import React, { useState,useEffect } from "react";
import "./tree.css";
import moment from "moment";
import { Container, Table } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faUser, faUserPlus, faUserGroup, faCalculator, faFile, faWallet, faMoneyCheck } from "@fortawesome/free-solid-svg-icons";
import { Button } from "react-bootstrap";
import axios from "axios";
import { useParams } from "react-router-dom";


function TreeView() {
  const [menuVisible, setMenuVisible] = useState(false);
  const [userId, setUserId] = useState(null);
  const [userToken, setUserToken] = useState(null);
  const [useReferal, setUseReferal] = useState(null);
  const { u_id } = useParams();
  const [users, setUsers] = useState([]);
  const [error, setError] = useState(null);
  const[personDetails,setPersondetails]=useState([]);
  
  useEffect(() => {
    const storedUserId = localStorage.getItem("u_id");
    const storedUserToken = localStorage.getItem("user_token");
    const storedRefID = localStorage.getItem("u_ref_id");

    if (storedUserId && storedUserToken) {
      setUserId(storedUserId);
      setUserToken(storedUserToken);
      setUseReferal(storedRefID);

      console.log("Retrieved u_id:", storedUserId);
      console.log("Retrieved user_token:", storedUserToken);
      console.log("Retrieved u_ref_id:", storedRefID);
    } else {
      console.log("No user data found in localStorage.");
    }
  }, []);
 useEffect(() => {
  const storedUserId = localStorage.getItem("u_id");
    const storedUserToken = localStorage.getItem("user_token");
    const storedRefID = localStorage.getItem("u_ref_id");
    console.log(" useEffect Retrieved u_id:", storedUserId);
    console.log("useEffect Retrieved user_token:", storedUserToken);
    console.log("useEffectRetrieved u_ref_id:", storedRefID);

    const fetchTreeView = async () => {
      try {
        const response = await axios.post("https://lunarsenterprises.com:6012/apnastore/list/refferal-users", { u_id: storedUserId });
        console.log(response);
        setPersondetails(response.data.list); 
      } catch (error) {
        console.error('Error fetching tree view:', error); 
      }
    };
  
    fetchTreeView();
 }, [])
  const toggleMenu = () => {
    setMenuVisible(!menuVisible);
  };

  return (
    <div className="container">
      <Button
        className="menus-button d-md-none" // Hide on medium and larger screens
        onClick={toggleMenu}
      >
        <FontAwesomeIcon icon={faBars} /> Menu
      </Button>
      <div className="row">
        <div className={`col-md-4 ${menuVisible ? 'd-block' : 'd-none d-md-block'}`}>
        <div className="profile-page container">
          <p>
              <a className="profile-menu" href="/levelplan">
                <FontAwesomeIcon className="me-3" icon={faCalculator} />
                Dashboard
              </a>
            </p>
            <hr className="profile-break" />
            <p>
              <a className="profile-menu" href="/profile">
                <FontAwesomeIcon className="me-3" icon={faUser} />
                Profile
              </a>
            </p>
            <hr className="profile-break" />
            <p>
              <a className="profile-menu" href="/newregister">
                <FontAwesomeIcon className="me-3" icon={faUserPlus} />
                New Registration
              </a>
            </p>
            <hr className="profile-break" />
            <p>
              <a className="profile-menu" href="/treeview">
                <FontAwesomeIcon className="me-3" icon={faUserGroup} />
                Tree View
              </a>
            </p>
            <hr className="profile-break" />
          
            <p>
              <a className="profile-menu" href="/order">
                <FontAwesomeIcon className="me-3" icon={faFile} />
                Order History
              </a>
            </p>
            <hr className="profile-break" />
            <a className="profile-menu" href="/wallet">
              <FontAwesomeIcon className="me-3" icon={faWallet} />
              Wallet
            </a>
            <hr className="profile-break" />
            <p>
              <a className="profile-menu" href="/transaction">
                <FontAwesomeIcon className="me-3" icon={faMoneyCheck} />
                Transaction History
              </a>
            </p>
          </div>
        </div>

        <div className="col-md-8">
          <div className="table-container">
            <div className="profile-page container">
              {/* <table className="level-table"> */}
                <Container>
                  <Table hover responsive>
                <thead>
                  <tr>
                    <th>Sl No</th>
                    <th>ID</th>
                    <th>Name</th>
                    <th>Position</th>
                    {/* <th>Joining Date</th> */}
                  </tr>
                </thead>
                <tbody>
                {personDetails.map((item, index) => (
            <tr key={item.u_ref_id}> 
              <td>{index + 1}</td> 
              <td>{item.u_ref_id}</td>
              <td>{item.u_name}</td>
              <td>{item.u_position}</td>
            </tr>
          ))}
                
                </tbody>
                </Table>
                </Container>
              {/* </table> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TreeView;
