import React, { useEffect, useState } from "react";
import { Form, Button, Navbar, Offcanvas, Nav } from "react-bootstrap";
import Logo from "../../Assets/logo.png";
import "./header.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCartShopping,
  faHeart,
  faUser,
  faLocationDot,
} from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigate } from "react-router-dom";

function Header() {
  const navigate = useNavigate();
  const [userId, setUserId] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [showOffcanvas, setShowOffcanvas] = useState(false);

  const handleInputChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Search Query:", searchQuery);
  };

  useEffect(() => {
    const storedUserId = localStorage.getItem("u_id");
    const storedUserToken = localStorage.getItem("user_token");
    if (storedUserId && storedUserToken) {
      setUserId(storedUserId);
      setIsLoggedIn(true); // Set user as logged in
      console.log("User logged in:", storedUserId);
    } else {
      console.log("No user data found in localStorage.");
    }
  }, []);

  const toggleOffcanvas = () => setShowOffcanvas(!showOffcanvas);

  const handleLoginLogout = () => {
    if (isLoggedIn) {
      // Clear user data and log out
      localStorage.removeItem("u_id");
      localStorage.removeItem("user_token");
      setUserId(null);
      setIsLoggedIn(false);
      navigate("/");
    } else {
      navigate("/login");
    }
  };

  return (
    <>
      <div className="bg-black">
        <div className="header-logo d-flex align-items-center justify-content-between">
          <div className="logo-container">
            <img className="logo" src={Logo} alt="logo" />
          </div>

          {/* Toggle Button for Offcanvas Menu */}
          <div className="d-flex justify-content-between align-items-center">
            <Button
              href="/register"
              className="header-sign-in-button mt-3 d-lg-none"
            >
              Sign Up
            </Button>

            {isLoggedIn && (
              <>
                <Link to="/profile">
                  <FontAwesomeIcon
                    className="top-profile-icon me-2 mt-3 d-lg-none"
                    icon={faUser}
                  />
                </Link>
                <Link to="/cart">
                  <FontAwesomeIcon
                    className="top-profile-icon me-2 mt-3 d-lg-none"
                    icon={faCartShopping}
                  />
                </Link>
                <Link to="/wishlist">
                  <FontAwesomeIcon
                    className="top-profile-icon me-2 mt-3 d-lg-none"
                    icon={faHeart}
                  />
                </Link>
              </>
            )}

            <Button
              onClick={toggleOffcanvas}
              className="toggle-button me-2 d-lg-none mt-2"
              aria-controls="offcanvasNavbar"
              aria-expanded={showOffcanvas}
            >
              ☰
            </Button>
          </div>

          <div className="button-container d-none d-lg-flex align-items-center">
            {!isLoggedIn && (
              <Link
                to="/register"
                className="header-button btn me-2"
                style={{ backgroundColor: "transparent", color: "white" }}
              >
                REGISTER
              </Link>
            )}

            {isLoggedIn && (
              <>
                {/* <div
                  style={{
                    borderLeft: "1px solid white",
                    height: "20px",
                    margin: "0 10px",
                  }}
                ></div> */}
                <Link to="/cart">
                  <button
                    style={{ backgroundColor: "transparent", color: "white" }}
                    className="header-button btn me-2"
                  >
                    <FontAwesomeIcon icon={faCartShopping} />
                  </button>
                </Link>
                <Link to="/wishlist">
                  <button
                    style={{ backgroundColor: "transparent", color: "white" }}
                    className="header-button btn me-2"
                  >
                    <FontAwesomeIcon icon={faHeart} />
                  </button>
                </Link>
                <button className="header-button btn me-2">
                  <Link to="/profile">
                    <FontAwesomeIcon className="profile" icon={faUser} />
                  </Link>
                </button>
              </>
            )}
          </div>
        </div>

        <div className="container-fluid">
          <div className="search-icon row">
            <div className="d-flex justify-content-between align-items-center">
              <div className="main-container-icons d-flex flex-grow-1 justify-content-around">
                <a href="/" className="main-icons">
                  HOME
                </a>
                <a href="/about" className="main-icons">
                  ABOUT
                </a>
                <a href="/products" className="main-icons">
                  PRODUCTS
                </a>
                <a href="/insurance" className="main-icons">
                  INSURANCE
                </a>
                <a href="/contact" className="main-icons">
                  CONTACT
                </a>
              </div>

              <Button
                onClick={handleLoginLogout}
                className="logins-buttons ms-auto"
              >
                {isLoggedIn ? "LOGOUT" : "LOGIN"}
              </Button>
            </div>
          </div>
        </div>

        <div className="container bg-black mt-3">
          <div className="store-headlinediv">
            <h5 className="store-headline">𝚆𝙴𝙻𝙲𝙾𝙼𝙴 𝚃𝙾 𝙺𝙰𝙿𝙽𝙰 𝚂𝚃𝙾𝚁𝙴</h5>
          </div>
        </div>
      </div>

      {/* Offcanvas Menu */}
      <Offcanvas
        show={showOffcanvas}
        onHide={() => setShowOffcanvas(false)}
        placement="end"
        id="offcanvasNavbar"
        className="nav-bar"
      >
        <Offcanvas.Body>
          {/* Profile Icon */}
          {isLoggedIn && (
            <div className="offcanvas-profile-icon">
              <a href="/profile">
                <FontAwesomeIcon icon={faUser} />
              </a>
            </div>
          )}

          <div className="offcanvas-profile-log-icon d-flex">
            <Button
              className="closing-menu-button"
              onClick={() => setShowOffcanvas(false)}
            >
              Ⅹ
            </Button>
            {/* <Link to="/login" className="header-button btn me-2" style={{ backgroundColor: "transparent", color: "white" }}>
              LOGIN
            </Link>
            <div style={{ borderLeft: "1px solid white", height: "20px", marginTop: "15px" }}></div>
            <Link to="/register" className="header-button btn me-2" style={{ backgroundColor: "transparent", color: "white" }}>
              REGISTER
            </Link> */}
          </div>

          <Nav className="flex-column">
            <Nav.Link className="offcanvas-icons" href="/">
              HOME
            </Nav.Link>
            <Nav.Link className="offcanvas-icons" href="/products">
              PRODUCTS
            </Nav.Link>
            <Nav.Link className="offcanvas-icons" href="/about">
              ABOUT
            </Nav.Link>
            <Nav.Link className="offcanvas-icons" href="/contact">
              CONTACT
            </Nav.Link>
            <Nav.Link className="offcanvas-icons" href="/insurance">
              INSURANCE
            </Nav.Link>
            <Nav.Link className="offcanvas-icons" href="/cart">
              ADD TO CART
            </Nav.Link>
          </Nav>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}

export default Header;
