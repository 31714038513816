import React, { useState, useEffect } from "react";
import "./home.css";
import Carousel from "react-bootstrap/Carousel";
import { Card, Row, Col, Container } from "react-bootstrap";
import one from "../../Assets/001.png";
import two from "../../Assets/002.png";
import threes from "../../Assets/003.png";
import four from "../../Assets/004.png";
import ones from "../../Assets/ones.png";
import twos from "../../Assets/twos.png";
import fours from "../../Assets/threes.png";
import axios from "axios";

function Home() {
  const [carouselItems, setCarouselItems] = useState([]);
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 769);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 769);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    axios
      .post("https://lunarsenterprises.com:6012/apnastore/list-banner")
      .then((response) => {
        console.log(response.data.list);
        setCarouselItems(response.data.list || []);
      })
      .catch((error) => {
        console.error("Error fetching carousel images:", error);
      });
  }, []);

  const [testimonials, setTestimonials] = useState([]);

  useEffect(() => {
    axios
      .post("https://lunarsenterprises.com:6012/apnastore/list-testimonial", {
        t_id: 1,
      })
      .then((response) => {
        if (response.data.result) {
          setTestimonials(response.data.list);
        }
      })
      .catch((error) => {
        console.error("There was an error fetching the testimonials!", error);
      });
  }, []);

  return (
    <div>
      {/* Images section */}
      <div className="d-flex justify-content-center">
        <div className="col-4 d-flex justify-content-center custom-image-contai">
          <img src={ones} alt="time" className="custom-one-image" />
        </div>
        <div className="col-4 d-flex justify-content-center custom-image-contai">
          <img src={twos} alt="badge" className="custom-two-image" />
        </div>
        <div className="col-4 d-flex justify-content-center custom-image-contai">
          <img src={fours} alt="everty" className="custom-three-image" />
        </div>
      </div>

      {/* Full-Screen Carousel */}
      <div className="full-screen-carousel">
        <Carousel>
          {carouselItems.length > 0 ? (
            carouselItems.map((item) => (
              <Carousel.Item key={item.b_id}>
                <img
                  className="home-image d-block"
                  src={`https://lunarsenterprises.com:6012/${item.b_image}`}
                  alt=""
                />
              </Carousel.Item>
            ))
          ) : (
            <div>
              <img
                src="https://static.vecteezy.com/system/resources/thumbnails/033/746/375/small_2x/a-cup-of-tea-and-a-cup-of-coffee-on-a-table-ai-generated-photo.jpg"
                alt=""
              />
            </div>
          )}
        </Carousel>
      </div>

      {/* Cards Section */}
      <div
        className={`card-container container ${
          isSmallScreen ? "scrollable-row" : ""
        }`}
      >
        {isSmallScreen ? (
          // Carousel for Small Screens (under 768px)
          <Carousel>
            {[one, two, threes, four].map((image, index) => (
              <Carousel.Item key={index}>
                <Card.Img variant="top" src={image} />
              </Carousel.Item>
            ))}
          </Carousel>
        ) : (
          // Static Cards for Large Screens (768px and above)
          <Row className="d-flex flex-nowrap">
            <Col xs={12} sm={6} md={3}>
              <Card.Img variant="top" src={one} />
            </Col>
            <Col xs={12} sm={6} md={3}>
              <Card.Img variant="top" src={two} />
            </Col>
            <Col xs={12} sm={6} md={3}>
              <Card.Img variant="top" src={threes} />
            </Col>
            <Col xs={12} sm={6} md={3}>
              <Card.Img variant="top" src={four} />
            </Col>
          </Row>
        )}
      </div>

      {/* Mission and Vision Section */}
      <div className="container mb-5 ">
        <div className="home-contents row mt-3">
          <div className="col-12 col-md-6 d-none d-md-block">
            <img
              className="mission-image img-fluid"
              src="https://images.thequint.com/thequint%2F2022-08%2F9554ffa8-f0aa-4a3b-8d5b-2eb33723c56c%2Fwhite_cup_with_tea_in_hand_on_background_of_green_tea_plantation_and_picture_id1212664938.jpg"
              alt="Tea in hand on green tea plantation"
            />
          </div>

          <div className="col-12 col-md-6 d-flex flex-column">
            <h4 className="mt-2">OUR MISSION</h4>
            <p className="mission-notes mt-3">
            Reinventing our world over a cup of tea is not just about the
              beverage itself; it is a call to action. By committing to
              sustainable practices, championing social responsibility, and
              cultivating mindfulness, we can inspire a movement that extends
              beyond our individual lives. Together, we can create a more just,
              equitable, and thriving world, one cup at a time.
            </p>
            <h4>VISION</h4>
            <p className="mission-notes mt-3">
            We envision a world where every cup of tea is a journey of
              discovery and delight, offering a meticulously curated selection
              of premium loose leaf teas, herbal blends, and artisanal infusions
              sourced from the finest gardens globally. Our diverse range caters
              to all tastes, promoting wellness and mindfulness, while our
              commitment to innovation brings unique blends and seasonal
              specialties to inspire curiosity. With a focus on sustainability
              and ethical sourcing, we aim to create a haven for tea lovers,
              fostering a community united by a shared passion for this timeless
              beverage and celebrating the art of tea and the moments it
              creates.
            </p>
          </div>
        </div>
      </div>

      {/* Testimonials Section */}
      <Container className="my-5">
        <h2 className="text-center mb-3">What People Have To Say About Us</h2>
        <Row className="justify-content-center">
          {testimonials.map((testimonial) => (
            <Col
              key={testimonial.t_id}
              xs={12}
              md={6}
              className="d-flex align-items-center justify-content-center"
            >
              <Card className="shadow-sm m-3" style={{ width: "18rem" }}>
                <Card.Img
                  variant="top"
                  src={`https://lunarsenterprises.com:6012${testimonial.t_image}`}
                  alt={testimonial.t_name}
                />
                <Card.Body>
                  <Card.Title
                    className="mt-2"
                    style={{ textTransform: "uppercase", fontWeight: "bold" }}
                  >
                    {testimonial.t_name}
                  </Card.Title>

                  <Card.Text
                    className="font-italic"
                    style={{ fontWeight: "bold" }}
                  >
                    "{testimonial.t_description}"
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  );
}

export default Home;
