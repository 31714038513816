import React from "react";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import Home from "./Pages/Home/Home";
import Products from './Pages/product/Product';
import Selection from './Pages/selection/Selection';
import Login from './Pages/Login/Login';
import Register from './Pages/Register/Register';
import "bootstrap/dist/css/bootstrap.min.css";
import "font-awesome/css/font-awesome.min.css";
import About from "./Pages/About/About";
import Contact from "./Pages/contact/Contact";
import Profile from "./Pages/Profile/Profile";
import Wishlist from "./Pages/wishlist/Wishlist";
import Cart from "./Pages/AddtoCart/Cart";
import OrderHistory from "./Pages/Orderhistory/OrderHistory";
import Wallet from "./Pages/Wallet/Wallet";
import Transaction from "./Pages/Transaction/Transaction";
import TreeView from "./Pages/Treeview/tree";
import NewRegister from "./Pages/NewRegistration/Newregister";
import LevelPlan from "./Pages/LevelPlan/Levelplan";
import Payment from "./Pages/Payment/Payment";
import Insurance from "./Pages/Insurance/Insurance";
import Edit from "./Pages/edit/personaledit/Edit";
import Bank from "./Pages/edit/bankedit/Bank";
import Password from "./Pages/edit/ChangePassword/Change";
import ForgetPassword from "./Pages/Forgetpassword/Forget";
import Forget from "./Pages/LoginForgetPassword/Lforget";
import LoginPassword from "./Pages/LoginChangepassword/Lchange";

function Layout({ children }) {
  const location = useLocation();
  const hideHeaderFooter = [
    "/login",
    "/register",
    "/loginforgetpassword",
    "/loginchangepassword"
  ].includes(location.pathname);

  return (
    <>
      {!hideHeaderFooter && <Header />}
      {children}
      {!hideHeaderFooter && <Footer />}
    </>
  );
}

function App() {
  return (
    <BrowserRouter>
      <Layout>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/insurance" element={<Insurance />} />
          <Route path="/payment" element={<Payment />} />
          <Route path="/wishlist" element={<Wishlist />} />
          <Route path="/cart" element={<Cart />} />
          <Route path="/about" element={<About />} />
          <Route path="/products" element={<Products />} />
          <Route path="/selection/:p_id" element={<Selection />} />
          <Route path="/login" element={<Login />} />
          <Route path="/forgetpassword" element={<ForgetPassword />} />
          <Route path="/register" element={<Register />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/loginforgetpassword" element={<Forget />} />
          <Route path="/loginchangepassword" element={<LoginPassword />} />
          {/* profile details */}
          <Route path="/profile" element={<Profile />} />
          <Route path="/edit" element={<Edit />} />
          <Route path="/bankedit" element={<Bank />} />
          <Route path="/changepassword" element={<Password />} />
          <Route path="/newregister" element={<NewRegister />} />
          <Route path="/order" element={<OrderHistory />} />
          <Route path="/wallet" element={<Wallet />} />
          <Route path="/transaction" element={<Transaction />} />
          <Route path="/treeview" element={<TreeView />} />
          <Route path="/levelplan" element={<LevelPlan />} />
        </Routes>
      </Layout>
    </BrowserRouter>
  );
}

export default App;
