import "./about.css";

function About() {
  return (
    <>
      <div className="col-md-4 col-12 text-center d-block d-md-none mt-3">
  <img
    className="about-img img-fluid "
    src="https://cdn.shopify.com/s/files/1/0533/6743/9558/t/5/assets/pf-63c3773c--brandcontent5.jpg?v=1622037071"
    alt="EveryTee Image"
  />
</div>

      <div className="container about-container">
        <div className="row mt-3">
        <div className="col-md-4 col-12 text-center ">
  <img
    className="about-img img-fluid d-none d-md-block"
    src="https://cdn.shopify.com/s/files/1/0533/6743/9558/t/5/assets/pf-63c3773c--brandcontent5.jpg?v=1622037071"
    alt="EveryTee Image"
  />
</div>

          <div className="col-md-8 col-12">
            <h5 className="about-heading">About</h5>
            <p className="about-details">
              At EveryTee, we believe tea is more than just a morning
              ritual—it’s the start of a journey toward a better, more
              fulfilling life. Our life-changing guarantee goes beyond a cup of
              tea; it’s an opportunity for personal growth, well-being, and the
              chance to turn dreams into reality. Every sip brings you closer to
              a brighter future for yourself and your loved ones.
            </p>

            <h5 className="mt-5">Quality Product</h5>
            <p className="quality-paragraph mt-4">
              Taste and health united: Made with 100% Assam tea, Every Tea is
              synonymous with quality and taste. Rich in antioxidants, every tea
              protects the body from free radicals. Rich in elements that
              improve heart health and brain health, every tea also improves
              digestion. The natural rose flavor it contains enhances the
              quality of life along with the royal taste.
            </p>
          </div>
        </div>

        <div className="row text-center">
          <h5>Sales Partner</h5>
          <p className="quality-paragraph mt-3">
            The New World of Income: Every Tea is distinguished by its direct
            selling method without middlemen between manufacturers and
            consumers. We have put forward a great goal to create a flow of
            money in the society through an income scheme in which the income
            given to intermediaries and advertisers is returned to the customers
            in six levels. We are rebuilding a prosperous India by creating rich
            people including women and differently-abled people without any
            investment.
          </p>
        </div>

        <div className="row text-center mt-3">
          <h5>Insurance</h5>
          <p className="quality-paragraph mt-3">
            Every tea provides flavor and richness while providing a shield of
            safety. Every Tea provides an accidental insurance cover of Rs 5
            lakh on the purchase of one kilo packet. This cover will cover you
            and your family in case of any kind of accidental death. Even if an
            accident occurs or permanent disability occurs, Every guarantees you
            assistance up to five lakh rupees. IFFCO-Tokyo, one of the largest
            insurance companies in India, provides this protection to every
            Every Tea customer. Welcome to Everyone's family, which brings
            wealth, prosperity, security and health to every ordinary person.
          </p>
        </div>
      </div>
    </>
  );
}

export default About;
