import React, { useEffect, useState } from "react";
import { Button, Card, Col, Container, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart, faShoppingCart } from "@fortawesome/free-solid-svg-icons";
import PropTypes from "prop-types";
import axios from "axios";
import "./product.css";
import placeholderImg from "../../Assets/bag.png";
import { useNavigate } from "react-router";

const ProductItem = ({ product, onBuyNow, onToggleFavorite, isFavorite, onAddToCart }) => (
  <Card className="ezy__epgrid3-card position-relative p-2">
    <a
      href="#!"
      aria-label="Add to favorites"
      onClick={(e) => {
        e.preventDefault(); // Prevent the default anchor behavior
        onToggleFavorite(product.id, isFavorite ? 0 : 1); // Toggle favorite status
      }}
    >
      <div className="ezy__epgrid3-fav-icon">
        <FontAwesomeIcon icon={faHeart} color={isFavorite ? "red" : "gray"} />
      </div>
    </a>
    <Card.Body>
      <div className="ezy__epgrid3-card-img d-flex justify-content-center align-items-center h-100 px-4">
        <a href="#!">
          <img
            src={product.img || placeholderImg}
            alt={product.title || "Product Image"}
          />
        </a>
      </div>
    </Card.Body>
    <Card.Body className="h-100 pt-0 text-start">
      <a href="#!">
        <h5 className="ezy__epgrid3-title">{product.title}</h5>
      </a>
      <div className="ezy__epgrid3-details d-flex justify-content-between align-items-center my-2">
        <h5 className="ezy__epgrid3-price">₹ {product.price}</h5>
        <a href="#!" aria-label="Add to cart" onClick={() => onAddToCart(product)}>
          <h5 className="ezy__epgrid3-cart">
            <FontAwesomeIcon icon={faShoppingCart} />
          </h5>
        </a>
      </div>
      <div>
        <Button
          onClick={() => onBuyNow(product.id)}
          className="w-100"
          style={{
            backgroundColor: "#ffc107",
            color: "black",
            border: "none",
            borderRadius: "20px",
          }}
        >
          Buy Now
        </Button>
      </div>
    </Card.Body>
  </Card>
);

ProductItem.propTypes = {
  product: PropTypes.shape({
    img: PropTypes.string,
    title: PropTypes.string.isRequired,
    price: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
  }).isRequired,
  onBuyNow: PropTypes.func.isRequired,
  onToggleFavorite: PropTypes.func.isRequired,
  onAddToCart: PropTypes.func.isRequired,
  isFavorite: PropTypes.bool.isRequired,
};

const Epgrid3 = () => {
  const [products, setProducts] = useState([]);
  const [favorites, setFavorites] = useState({});
  const [userId, setUserId] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const storedUserId = localStorage.getItem("u_id");
    if (storedUserId) {
      setUserId(storedUserId);
    }
  }, []);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await axios.post(
          "https://lunarsenterprises.com:6012/apnastore/list-products"
        );
        const fetchedProducts = response.data.list
          ? response.data.list.map((product) => ({
              id: product.p_id,
              img: product.p_image ? `https://lunarsenterprises.com:6012/${product.p_image}` : placeholderImg,
              title: product.p_name || "Product Title",
              price: product.p_price || "0.00",
            }))
          : [];
        setProducts(fetchedProducts);
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    };

    fetchProducts();
  }, []);

  const handleBuyNow = (p_id) => {
    navigate(`/selection/${p_id}`);
  };

  const handleToggleFavorite = async (productId, favStatus) => {
    if (!userId) {
      console.log("No user ID found. Please log in first.");
      return;
    }
    try {
      const response = await axios.post(
        "https://lunarsenterprises.com:6012/apnastore/add-favorites",
        {
          fav: favStatus,
          user_id: userId,
          p_id: productId,
        }
      );
      if (response.status === 200) {
        setFavorites((prevFavorites) => ({
          ...prevFavorites,
          [productId]: favStatus,
        }));
      }
    } catch (error) {
      console.error("Error adding product to favorites:", error);
    }
  };

  const handleAddToCart = async (product) => {
    if (!userId) {
      console.log("No user ID found. Please log in first.");
      return;
    }

    try {
      const response = await axios.post(
        "https://lunarsenterprises.com:6012/apnastore/add-cart",
        {
          u_id: userId,
          p_id: product.id,
          quantity: 1, // Assuming 1 quantity for now, can be modified
        }
      );
      if (response.status === 200) {
        console.log("Product added to cart successfully!");
        navigate("/cart"); // Redirect to the cart page after adding
      }
    } catch (error) {
      console.error("Error adding product to cart:", error);
    }
  };

  return (
    <section className="ezy__epgrid3 light">
      <Container>
        <Row className="ezy__epgrid3-row text-center justify-content-xl-center mt-5">
          {products.length > 0 ? (
            products.map((product) => (
              <Col md={6} lg={4} xl={3} className="px-2 my-2" key={product.id}>
                <ProductItem
                  product={product}
                  onBuyNow={handleBuyNow}
                  onToggleFavorite={handleToggleFavorite}
                  onAddToCart={handleAddToCart}
                  isFavorite={favorites[product.id] === 1}
                />
              </Col>
            ))
          ) : (
            <p style={{ fontWeight: "bolder", color: "black", fontSize: "1rem" }}>
              No products available
            </p>
          )}
        </Row>
      </Container>
    </section>
  );
};

export default Epgrid3;
