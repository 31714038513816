import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import { useParams } from "react-router-dom";
import { Button, Container, Table } from "react-bootstrap";
import { faCalculator, faFile, faBars, faMoneyCheck, faUser, faUserGroup, faUserPlus, faWallet } from "@fortawesome/free-solid-svg-icons";
import React, { useState,useEffect } from "react";


function OrderHistory() {
  const [menuVisible, setMenuVisible] = useState(false);
  const [userId, setUserId] = useState(null);
  const [userToken, setUserToken] = useState(null);
  const [useReferal, setUseReferal] = useState(null);
  const { u_id } = useParams();
  const [users, setUsers] = useState([]);
  const [error, setError] = useState(null);
  const [order,setOrder]=useState([]);

  const toggleMenu = () => {
    setMenuVisible(!menuVisible);
  };



  useEffect(() => {
    const storedUserId = localStorage.getItem("u_id");
    const storedUserToken = localStorage.getItem("user_token");
    const storedRefID = localStorage.getItem("u_ref_id");

    if (storedUserId && storedUserToken) {
      setUserId(storedUserId);
      setUserToken(storedUserToken);
      setUseReferal(storedRefID);

      console.log("Retrieved u_id:", storedUserId);
      console.log("Retrieved user_token:", storedUserToken);
      console.log("Retrieved u_ref_id:", storedRefID);
    } else {
      console.log("No user data found in localStorage.");
    }
  }, []);

  useEffect(() => {
    const storedUserId = localStorage.getItem("u_id");
      const storedUserToken = localStorage.getItem("user_token");
      const storedRefID = localStorage.getItem("u_ref_id");
      console.log(" useEffect Retrieved u_id:", storedUserId);
      console.log("useEffect Retrieved user_token:", storedUserToken);
      console.log("useEffectRetrieved u_ref_id:", storedRefID);
  
      const fetchTreeView = async () => {
        try {
          const response = await axios.post("https://lunarsenterprises.com:6012/apnastore/list-order", { u_id: storedUserId });
          console.log(response);
          setOrder(response.data.list); 
        } catch (error) {
          console.error('Error fetching tree view:', error); 
        }
      };
    
      fetchTreeView();
   }, [])
  return (
    <>
      <div className="container">
      <Button
        className="menus-button d-md-none" // Hide on medium and larger screens
        onClick={toggleMenu}
      >
        <FontAwesomeIcon icon={faBars} /> Menu
      </Button>
        <div className="row">
        <div className={`col-md-4 ${menuVisible ? 'd-block' : 'd-none d-md-block'}`}>
        <div className="profile-page container">
          <p>
              <a className="profile-menu" href="/levelplan">
                <FontAwesomeIcon className="me-3" icon={faCalculator} />
                Dashboard
              </a>
            </p>
            <hr className="profile-break" />
            <p>
              <a className="profile-menu" href="/profile">
                <FontAwesomeIcon className="me-3" icon={faUser} />
                Profile
              </a>
            </p>
            <hr className="profile-break" />
            <p>
              <a className="profile-menu" href="/newregister">
                <FontAwesomeIcon className="me-3" icon={faUserPlus} />
                New Registration
              </a>
            </p>
            <hr className="profile-break" />
            <p>
              <a className="profile-menu" href="/treeview">
                <FontAwesomeIcon className="me-3" icon={faUserGroup} />
                Tree View
              </a>
            </p>
            <hr className="profile-break" />
          
            <p>
              <a className="profile-menu" href="/order">
                <FontAwesomeIcon className="me-3" icon={faFile} />
                Order History
              </a>
            </p>
            <hr className="profile-break" />
            <a className="profile-menu" href="/wallet">
              <FontAwesomeIcon className="me-3" icon={faWallet} />
              Wallet
            </a>
            <hr className="profile-break" />
            <p>
              <a className="profile-menu" href="/transaction">
                <FontAwesomeIcon className="me-3" icon={faMoneyCheck} />
                Transaction History
              </a>
            </p>
          </div>
        </div>
          <div className="col-md-8">
            <div className="profile-page container">
              <Container>
                <Table hover responsive>
                  <thead className="tables">
                    <tr className="tables">
                      <th className="custom-header">Order ID</th>
                      <th className="custom-header">Product</th>
                      <th className="custom-header">Quantity</th>
                      <th className="custom-header">Price</th>
                      <th className="custom-header">Status</th>
                      <th className="custom-header">Date</th>
                      <th className="custom-header">Pack of</th>
                    </tr>
                  </thead>
                  <tbody>
                    {order.map((order,index) => (
                      <tr key={order.id}>
                        <td>{index+1}</td>
                        <td>{order.p_name}</td>
                        <td>{order.p_quantity} kg</td>
                        <td>{order.p_price}</td>
                        <td>{order.od_status}</td>
                        <td>{order.od_created_at}</td>
                        <td>{order.op_quantity}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Container>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default OrderHistory;
