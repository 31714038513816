import { faCartShopping, faHeart } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Container, Row, Col, Button, Image, Form } from "react-bootstrap";
import { useParams, useNavigate, Link } from "react-router-dom";


function Selection() {
  const [quantity, setQuantity] = useState(1);
  const { p_id } = useParams();
  const [product, setProduct] = useState({});
  const [userId, setUserId] = useState(null);
  const [favorites, setFavorites] = useState({});
  const navigate = useNavigate();

  // Get user data from localStorage on mount
  useEffect(() => {
    const storedUserId = localStorage.getItem("u_id");
    const storedUserToken = localStorage.getItem("user_token");

    if (storedUserId && storedUserToken) {
      setUserId(storedUserId);
      console.log("User data found:", storedUserId);
    } else {
      console.log("No user data found in localStorage.");
    }
  }, []);

  // Handle quantity change for increment/decrement
  const handleQuantityChange = (type) => {
    setQuantity((prevQuantity) => {
      if (type === "increment" && prevQuantity < 10) {
        return prevQuantity + 1;
      } else if (type === "decrement" && prevQuantity > 1) {
        return prevQuantity - 1;
      }
      return prevQuantity;
    });
  };

  // Fetch product details from API
  useEffect(() => {
    const fetchProduct = async () => {
      try {
        const response = await axios.post("https://lunarsenterprises.com:6012/apnastore/list-products", { p_id });
        console.log("Product fetch response:", response);

        const productList = response.data.list || [];
        if (productList.length > 0) {
          setProduct(productList[0]);
        }
      } catch (error) {
        console.log("Error fetching product:", error);
      }
    };

    fetchProduct();
  }, [p_id]);

  // Toggle favorite status for the product
  const handleToggleFavorite = async () => {
    if (!userId) {
      console.log("No user ID found. Please log in first.");
      return;
    }

    const newFavStatus = !favorites[p_id];

    try {
      const response = await axios.post("https://lunarsenterprises.com:6012/apnastore/add-favorites", {
        fav: newFavStatus,
        user_id: userId,
        p_id,
      });

      if (response.status === 200) {
        setFavorites((prevFavorites) => ({
          ...prevFavorites,
          [p_id]: newFavStatus,
        }));
        navigate("/wishlist");
      }
    } catch (error) {
      console.error("Error adding product to favorites:", error);
    }
  };

  // Handle adding the product to the cart
  const handleAddToCart = async () => {
    if (!userId) {
      console.log("No user ID found. Please log in first.");
      return;
    }

    // Log the product ID and user ID to make sure they're available
    console.log("User ID:", userId);
    console.log("Product ID:", p_id);
    console.log("Quantity:", quantity);

    if (!product.p_id) {
      console.error("Product ID is missing!");
      return;
    }

    try {
      const response = await axios.post(
        "https://lunarsenterprises.com:6012/apnastore/add-cart",
        {
          u_id: userId,
          p_id: p_id,
          quantity,
        }
      );
      console.log("Add to Cart response:", response);

      if (response.status === 200) {
        console.log("Product added to cart successfully!");
        navigate("/cart"); // Redirect to the cart page after adding
      } else {
        console.error("Failed to add product to cart:", response);
      }
    } catch (error) {
      console.error("Error adding product to cart:", error);
    }
  };

  return (
    <Container className="my-5">
      <Row>
        <Col md={5} className="products-image">
          <Image
            src={`https://lunarsenterprises.com:6012${product.p_image}`}
            alt={product.p_name}
            fluid
            style={{ maxHeight: "500px" }}
          />
        </Col>

        <Col md={7}>
          <div className="d-flex justify-content-between align-items-center">
            <h2>{product.p_name}</h2>
            <FontAwesomeIcon
              icon={faHeart}
              onClick={handleToggleFavorite}
              style={{
                fontSize: "1.5rem",
                cursor: "pointer",
                color: favorites[p_id] ? "red" : "grey",
              }}
            />
          </div>
          <p className="text-muted">₹ {product.p_price}</p>
          <p>{product.p_description}</p>

          <Form.Group controlId="quantity" className="mt-3">
            <Form.Label>Quantity</Form.Label>
            <div className="d-flex align-items-center">
              <Button
                variant="outline-secondary"
                onClick={() => handleQuantityChange("decrement")}
                disabled={quantity === 1}
                style={{
                  width: "50px",
                  height: "40px",
                  borderRadius: "5px",
                  fontSize: "1.2rem",
                }}
              >
                -
              </Button>
              <Form.Control
                type="text"
                value={quantity}
                readOnly
                style={{
                  width: "60px",
                  height: "40px",
                  textAlign: "center",
                  borderRadius: "5px",
                  fontSize: "1.2rem",
                }}
              />
              <Button
                variant="outline-secondary"
                onClick={() => handleQuantityChange("increment")}
                disabled={quantity === 10}
                style={{
                  width: "50px",
                  height: "40px",
                  borderRadius: "5px",
                  fontSize: "1.2rem",
                }}
              >
                +
              </Button>
            </div>
          </Form.Group>

          <Button
            className="mt-4"
            onClick={handleAddToCart}
            style={{
              width: "100%",
              backgroundColor: "#ffc107",
              color: "black",
              border: "none",
              borderRadius: "20px",
            }}
          >
            <FontAwesomeIcon icon={faCartShopping} className="me-2" />
            Add to Cart
          </Button>

          <div className="mt-4">
            <Link to="/payment">
              <Button
                style={{
                  backgroundColor: "#ffc107",
                  color: "black",
                  border: "none",
                  borderRadius: "15px",
                }}
                className="buynow w-100"
              >
                Buy Now
              </Button>
            </Link>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default Selection;
