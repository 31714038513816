import React, { Fragment, useState } from "react";
import "./newregister.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import IndianStatesCitiesList from "indian-states-cities-list";
import axios from "axios";
import Select from 'react-select';
import {
  faBars,
  faUser,
  faUserPlus,
  faUserGroup,
  faCalculator,
  faFile,
  faWallet,
  faMoneyCheck,
} from "@fortawesome/free-solid-svg-icons";
import { Button,Form} from "react-bootstrap";

function Newregister() {
  const [menuVisible, setMenuVisible] = useState(false);
  const [validated, setValidated] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    mobile: "",
    address: "",
    district: "",
    state: "",
    zip_code: "",
    bank_name: "",
    account_no: "",
    bank_branch: "",
    ifsc_code: "",
    password: "",
    referral_id: ""
  });
  
  const [districtOptions, setDistrictOptions] = useState([]);

  const statesList = IndianStatesCitiesList.STATES_OBJECT;

  // Handler for state selection
  const handleStateChange = (selectedState) => {
    setFormData({ ...formData, state: selectedState.value });
    setDistrictOptions(IndianStatesCitiesList.STATE_WISE_CITIES[selectedState.value]?.map(city => ({
      value: city.label,
      label: city.label,
    })) || []);
  };

  const handleDistrictChange = (selectedDistrict) => {
    setFormData({ ...formData, district: selectedDistrict.value });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    
    if (form.checkValidity() === false) {
      event.stopPropagation();
    } else {
      try {
        const formDataToSend = new FormData();
        formDataToSend.append("name", formData.name);
        formDataToSend.append("email", formData.email);
        formDataToSend.append("mobile", formData.mobile);
        formDataToSend.append("address", formData.address);
        formDataToSend.append("district", formData.district);
        formDataToSend.append("state", formData.state);
        formDataToSend.append("zip_code", formData.zip_code);     
        formDataToSend.append("bank_name", formData.bank_name);
        formDataToSend.append("account_no", formData.account_no);  
        formDataToSend.append("bank_branch", formData.bank_branch);
        formDataToSend.append("ifsc_code", formData.ifsc_code);
        formDataToSend.append("password", formData.password);
        formDataToSend.append("referral_id", formData.referral_id);
        
        // Make an API call here to register the user
        const response = await axios.post(
          'https://lunarsenterprises.com:6012/apnastore/user-registration',
          formDataToSend,
          {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }
        );
  
        console.log("User registered:", response);
        
        // Optionally, handle successful registration (e.g., redirect to login)
        alert("Registration successful!");
      } catch (error) {
        console.error("Error registering user:", error);
        alert("Failed to register. Please try again.");
      }
    }
    
    setValidated(true);
  };
  

  const toggleMenu = () => {
    setMenuVisible(!menuVisible);
  };
  
  
  return (
    <>
      <div className="container">
      <Button
        className="menus-button d-md-none" // Hide on medium and larger screens
        onClick={toggleMenu}
      >
        <FontAwesomeIcon icon={faBars} /> Menu
      </Button>
        <div className="row">
        <div className={`col-md-4 ${menuVisible ? 'd-block' : 'd-none d-md-block'}`}>
        <div className="profile-page container">
          <p>
              <a className="profile-menu" href="/levelplan">
                <FontAwesomeIcon className="me-3" icon={faCalculator} />
                Dashboard
              </a>
            </p>
            <hr className="profile-break" />
            <p>
              <a className="profile-menu" href="/profile">
                <FontAwesomeIcon className="me-3" icon={faUser} />
                Profile
              </a>
            </p>
            <hr className="profile-break" />
            <p>
              <a className="profile-menu" href="/newregister">
                <FontAwesomeIcon className="me-3" icon={faUserPlus} />
                New Registration
              </a>
            </p>
            <hr className="profile-break" />
            <p>
              <a className="profile-menu" href="/treeview">
                <FontAwesomeIcon className="me-3" icon={faUserGroup} />
                Tree View
              </a>
            </p>
            <hr className="profile-break" />
          
            <p>
              <a className="profile-menu" href="/order">
                <FontAwesomeIcon className="me-3" icon={faFile} />
                Order History
              </a>
            </p>
            <hr className="profile-break" />
            <a className="profile-menu" href="/wallet">
              <FontAwesomeIcon className="me-3" icon={faWallet} />
              Wallet
            </a>
            <hr className="profile-break" />
            <p>
              <a className="profile-menu" href="/transaction">
                <FontAwesomeIcon className="me-3" icon={faMoneyCheck} />
                Transaction History
              </a>
            </p>
          </div>
        </div>
        <div className="col-md-8" >
        <div className="profile-page container">
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
      <Form.Group className="mb-2 mt-2">
        <Form.Label>Name</Form.Label>
        <Form.Control 
          type="text" 
          placeholder="Enter Your Name" 
          name="name"
          required 
          onChange={handleChange} 
        />
        <Form.Control.Feedback type="invalid">
          Please enter your name.
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group className="mb-2 mt-2">
        <Form.Label>Email Address</Form.Label>
        <Form.Control 
          type="email" 
          placeholder="Enter Email Address" 
          name="email"
          required 
          onChange={handleChange} 
        />
        <Form.Control.Feedback type="invalid">
          Please enter a valid email address.
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group className="mb-2 mt-2">
        <Form.Label>Mobile</Form.Label>
        <Form.Control 
          type="tel" 
          placeholder="Enter Mobile Number" 
          name="mobile"
          required 
          onChange={handleChange} 
        />
        <Form.Control.Feedback type="invalid">
          Please enter a valid mobile number.
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group className="mb-2 mt-2">
        <Form.Label>Address</Form.Label>
        <Form.Control 
          type="text" 
          placeholder="Enter Your Address" 
          name="address"
          required 
          onChange={handleChange} 
        />
        <Form.Control.Feedback type="invalid">
          Please enter your house name.
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group className="mb-2 mt-2">
        <Form.Label>State</Form.Label>
        <Select
          options={statesList}
          placeholder="Select State"
          name="state"
          isClearable
          onChange={handleStateChange}
          className="form-control p-0"
          styles={{
            control: (base) => ({
              ...base,
              borderColor: "#ced4da",
              boxShadow: "none",
            }),
          }}
        />
      </Form.Group>

      <Form.Group className="mb-2 mt-2">
        <Form.Label>District</Form.Label>
        <Select
          options={districtOptions}
          placeholder="Select District"
          name="district"
          isClearable
          onChange={handleDistrictChange}
          className="form-control p-0"
          styles={{
            control: (base) => ({
              ...base,
              borderColor: "#ced4da",
              boxShadow: "none",
            }),
          }}
        />
      </Form.Group>

      <Form.Group className="mb-2 mt-2">
        <Form.Label>Zip Code</Form.Label>
        <Form.Control 
          type="text" 
          placeholder="Enter Pincode" 
          name="zip_code"
          required 
          onChange={handleChange} 
        />
        <Form.Control.Feedback type="invalid">
          Please enter your zip_code.
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group className="mb-2 mt-2">
        <Form.Label>Bank Name</Form.Label>
        <Form.Control 
          type="text" 
          placeholder="Enter Bank name" 
          name="bank_name"
          required 
          onChange={handleChange} 
        />
        <Form.Control.Feedback type="invalid">
          Please enter bank name.
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group className="mb-2 mt-2">
        <Form.Label>Account Number</Form.Label>
        <Form.Control 
          type="text" 
          placeholder="Enter Account Number" 
          name="account_no"
          required 
          onChange={handleChange} 
        />
        <Form.Control.Feedback type="invalid">
          Please enter your account number.
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group className="mb-2 mt-2">
        <Form.Label>Bank Branch</Form.Label>
        <Form.Control 
          type="text" 
          placeholder="Enter Bank Branch" 
          name="bank_branch"
          required 
          onChange={handleChange} 
        />
        <Form.Control.Feedback type="invalid">
          Please enter your bank branch.
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group className="mb-2 mt-2">
        <Form.Label>IFSC Code</Form.Label>
        <Form.Control 
          type="text" 
          placeholder="Enter IFSC Code" 
          name="ifsc_code"
          required 
          onChange={handleChange} 
        />
        <Form.Control.Feedback type="invalid">
          Please enter a valid IFSC Code.
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group className="mb-2 mt-2">
        <Form.Label>Password</Form.Label>
        <Form.Control 
          type="password" 
          placeholder="Enter Password" 
          name="password"
          required 
          onChange={handleChange} 
        />
        <Form.Control.Feedback type="invalid">
          Please enter a password.
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group className="mb-2 mt-2">
        <Form.Label>Referral ID</Form.Label>
        <Form.Control 
          type="text" 
          placeholder="Enter Referral ID" 
          name="referral_id"
          onChange={handleChange} 
        />
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Check
          type="checkbox"
          id="signin6-remember-me"
          label="Remember me"
        />
      </Form.Group>

      <Button
        variant="primary"
        type="submit"
        className="registration-button w-100"
      >
        Register
      </Button>

      {/* <p className="mb-4 mb-md-5">
        <span className="mb-0 opacity-50 lh-1">Already have an account?</span>
        <Button
        href="/login"
          variant="link"
          className="log-button text-decoration-none mb-0"
          onClick={() => console.log("Redirect to Login Page")}
        >
          Login
        </Button>
      </p> */}

      {/* <div className="divider-text">OR</div> */}
      {/* <div className="social-icons">
        <Button className="mb-2" variant="outline-secondary">
          <FontAwesomeIcon icon={faFacebook} />
        </Button>
        <Button variant="outline-secondary">
          <FontAwesomeIcon icon={faGoogle} />
        </Button>
      </div> */}
    </Form>
    </div></div>
        </div>
        </div>
      
    </>
  );
}

export default Newregister;
