import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faTwitter, faInstagram } from '@fortawesome/free-brands-svg-icons';
import './footer.css'; // Import the external CSS file
import Logo from '../../Assets/logo.png'

const Footer = () => {
  return (
    <footer className="footer">
      <div className="container">
        <div className="row text-center text-md-left">
          <div className="the-logo col-md-4 mb-4 mb-md-0">
          <img className='footer-logos' src={Logo} alt="Logo" />
            <p>Awaken Your Senses with Every Sip.</p>
          </div>
          <div className="col-md-4 mb-4 mb-md-0">
            <h5>Quick Links</h5>
            <ul className="list">
              <li><a href="/">HOME</a></li>
              <li><a href="/products">PRODUCTS</a></li>
              <li><a href="/about">ABOUT US</a></li>   
              <li><a href="/contact">CONTACT</a></li>
              <li><a href="/insurance">INSURANCE</a></li>
            </ul>
          </div>
          <div className="col-md-4 mb-4 mb-md-0">
            <h5>Follow Us</h5>
            <div className="social-icons">
              <a href="#facebook"><FontAwesomeIcon icon={faFacebookF} /></a>
              <a href="#twitter"><FontAwesomeIcon icon={faTwitter} /></a>
              <a href="#instagram"><FontAwesomeIcon icon={faInstagram} /></a>
            </div>
          </div>
        </div>
        <hr />
        <div className="row mt-4">
          <div className="col text-center">
            <p>&copy; 2024 Kapna Store. All rights reserved.</p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
