import React, { Fragment, useState } from "react";
import { Button, Card, Col, Container, Form, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebook, faGoogle } from "@fortawesome/free-brands-svg-icons";
import './login.css';
import kapna from "../../Assets/kapna-round.png"
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from "react-router";



// const SocialLoginButton = () => 
// 	<Fragment>
// 		<Button
// 			variant="primary"
// 			className="ezy__signin6-btn w-100 d-flex align-items-center mb-3"
// 		>
// 			<span className="text-white fs-4 lh-1">
// 				<FontAwesomeIcon icon={faFacebook} />
// 			</span>
// 			<span className="w-100 text-center text-white">
// 				Continue with Facebook
// 			</span>
// 		</Button>
// 		<Button
// 			variant="danger"
// 			className="ezy__signin6-btn w-100 d-flex align-items-center"
// 		>
// 			<span className="text-white fs-4 lh-1">
// 				<FontAwesomeIcon icon={faGoogle} />
// 			</span>
// 			<span className="w-100 text-center text-white">Continue with Google</span>
// 		</Button>
// 	</Fragment>
// );

const SignInForm = () => {
	const [validated, setValidated] = useState(false);
	const [email,setEmail]=useState("");
	const[password,setPassword]=useState("");
	const [message, setMessage] = useState('');
	const navigate =useNavigate();


	const handleSubmit = async (event) => {
		event.preventDefault();
		const form = event.currentTarget;
	
		if (form.checkValidity() === false) {
		  event.stopPropagation();
		} else {
		  try {
			const response = await axios.post('https://lunarsenterprises.com:6012/apnastore/user-login', { email, password });
			setMessage(response.data.message);
	  
			if (response.data.result) {
			 
			  localStorage.setItem('u_id', response.data.u_id);	
			  localStorage.setItem('u_ref_id', response.data.u_ref_id);	  
			  localStorage.setItem('user_token', JSON.stringify(response.data.user_token));

			  console.log("u_id",localStorage.getItem('u_id'));
			  console.log("u_ref_id",localStorage.getItem('u_ref_id'));
			  console.log("user_token:",localStorage.getItem('user_token'));
			  
			  
			  toast.success(response.data.message);
			  navigate('/levelplan'); 
			} else {
			  toast.info(response.data.message);
			}
	  
		  } catch (error) {
			setMessage(error.response ? error.response.data.message : 'An error occurred');
		  }
		}
	  
		setValidated(true);
	  };
	  

	return (
		<Form
		className="pe-md-4"
		noValidate
		validated={validated}
		onSubmit={handleSubmit}
	  >
		<Form.Group className="mb-2 mt-2">
		  <Form.Label>Enter email ID</Form.Label>
		  <Form.Control
			type="email"
			placeholder="Enter email ID"
			value={email}
			onChange={(e) => setEmail(e.target.value)}
			required
			isInvalid={validated && !email} 
		  />
		  <Form.Control.Feedback type="invalid">Please provide a valid email.</Form.Control.Feedback>
		</Form.Group>
  
		<Form.Group className="mb-2 mt-2">
		  <Form.Label>Password</Form.Label>
		  <Form.Control
			type="password"
			placeholder="Enter Password"
			value={password}
			onChange={(e) => setPassword(e.target.value)}
			required
			isInvalid={validated && !password} // Show invalid feedback if password is empty
		  />
		  <Form.Control.Feedback type="invalid">Please enter a password.</Form.Control.Feedback>
		</Form.Group>
  <div className="d-flex">
		<Form.Group className="mb-3">
		  <Form.Check
			type="checkbox"
			id="signin6-remember-me"
			label="Remember me"
		  />
		  <Form.Control.Feedback type="valid">Message</Form.Control.Feedback>
		</Form.Group>
		<Button href="/loginforgetpassword" variant="link" className="sign-up-button py-0 text-decoration-none ms-auto">
			Forget Password
		  </Button>
		</div>
  
		<Button
		  variant="primary"
		  type="submit"
		  className="ezy__signin6-btn-submit w-100"
		>
		  Log In
		</Button>
  
		{message && <p className="mt-3 text-center text-danger">{message}</p>}
  
		<p className="mb-4 mb-md-5">
		  <span className="mb-0 opacity-50 lh-1 text-black">Don't have an account?</span>
		  <Button href="/register" variant="link" className="sign-up-button py-0 text-decoration-none">
			Sign Up Here
		  </Button>
		</p>
		<ToastContainer />
	  </Form>
	  
	);
};

const SignInFormCard = () => (
	<div className="ezy__signin6-form-card">
		<Card.Body className="p-0">
			<div className="kapna-image">
			<img className="image-logos" src={kapna} alt="" />
			</div>
			{/* <h2 className="ezy__signin6-heading mb-3">Welcome to Easy Frontend</h2> */}
			

			<SignInForm />

			{/* <div className="position-relative ezy__signin6-or-separator">
				<hr className="my-4 my-md-5" />
				<span className="px-2">Or</span>
			</div> */}

			{/* <SocialLoginButton /> */}
		</Card.Body>
	</div>
);

const SignIn6 = () => {
	return (
		<section className="ezy__signin6 light d-flex">
			<Container>
				<Row className="justify-content-between h-100">
					<Col lg={6}>
						<div
							className="ezy__signin6-bg-holders d-none d-lg-block"
							style={{
								backgroundImage:
									"url(https://www.naatigrains.com/image/cache/catalog/naatigrains-products/NG204/puruvi-premium-quality-ctc-tea-natural-powder-black-naati-grains-1000x1000.jpg)",
							}}
						/>
					</Col>
					<Col lg={5} className="py-5">
						<Row className="align-items-center">
							<Col xs={12}>
								<SignInFormCard />
							</Col>
						</Row>
					</Col>
				</Row>
			</Container>

		</section>
	);
};
export default SignIn6;