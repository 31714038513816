import React, { useState, useEffect } from "react";
import { Container, Row, Col, Card, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShoppingCart } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import placeholderImg from "../../Assets/bag.png";

const CartPage = () => {
  const [products, setProducts] = useState([]);
  const navigate = useNavigate();
  const [userId, setUserId] = useState(null);

  useEffect(() => {
    const storedUserId = localStorage.getItem("u_id");
    const storedUserToken = localStorage.getItem("user_token");

    if (storedUserId && storedUserToken) {
      setUserId(storedUserId);
    }
  }, []);

  useEffect(() => {
    if (!userId) return;

    const fetchCartProducts = async () => {
      try {
        const response = await axios.post(
          "https://lunarsenterprises.com:6012/apnastore/list-cart",
          { u_id: userId }
        );
        console.log(response.data); 

        const productsData = response.data.list.map((product) => ({
          id: product.p_id,
          title: product.p_name,
          price: product.p_price,
          img: product.p_image
            ? `https://lunarsenterprises.com${product.p_image}`
            : placeholderImg,
        }));

        setProducts(productsData);
      } catch (error) {
        console.error("Error fetching cart products:", error);
      }
    };

    fetchCartProducts();
  }, [userId]);

  // Handle the 'Buy Now' functionality
  const handleBuyNow = (p_id) => {
    navigate(`/selection/${p_id}`);
  };

  // Handle removing product from cart
  const handleRemoveFromCart = async (productId) => {
    console.log("Removing from cart, product ID:", productId); // Check if function is triggered
    try {
      const response = await axios.delete(
        "https://lunarsenterprises.com:6012/apnastore/remove-from-cart",
        { data: { u_id: userId, p_id: productId } } // `data` for DELETE request payload
      );
      console.log(response.data); // Log to check if removal is successful
  
      if (response.data.success) {
        setProducts((prevProducts) =>
          prevProducts.filter((product) => product.id !== productId)
        );
      } else {
        console.log("Failed to remove product from cart.");
      }
    } catch (error) {
      console.error("Error removing product from cart:", error.response || error.message);
    }
  };

  return (
    <section className="ezy__epgrid3 light">
      <Container>
        <Row className="ezy__epgrid3-row text-center justify-content-xl-center mt-5">
          {products.length > 0 ? (
            products.map((product) => (
              <Col md={6} lg={4} xl={3} className="px-2 my-2" key={product.id}>
                <ProductItem
                  product={product}
                  onBuyNow={handleBuyNow}
                  onRemoveFromCart={handleRemoveFromCart}
                />
              </Col>
            ))
          ) : (
            <p style={{ fontWeight: "bolder", color: "black", fontSize: "1rem" }}>
              No products available
            </p>
          )}
        </Row>
      </Container>
    </section>
  );
};

const ProductItem = ({ product, onBuyNow, onRemoveFromCart }) => {
  const handleRemoveClick = (productId) => {
    console.log("Removing product with id:", productId); // Log to check if it triggers
    onRemoveFromCart(productId);
  };

  return (
    <Card className="ezy__epgrid3-card position-relative p-2">
      <a href="#!" aria-label="Remove from cart">
        <div className="ezy__epgrid3-fav-icon">
          {/* Remove from Cart Button */}
          <FontAwesomeIcon
            icon={faShoppingCart}
            onClick={() => handleRemoveClick(product.id)} // Ensure it triggers onRemoveFromCart
            style={{ cursor: "pointer", color: "red" }}
          />
        </div>
      </a>
      <Card.Body>
        <div className="ezy__epgrid3-card-img d-flex justify-content-center align-items-center h-100 px-4">
          <a href="#!">
            <img
              src={product.img || placeholderImg}
              alt={product.title || "Product Image"}
            />
          </a>
        </div>
      </Card.Body>
      <Card.Body className="h-100 pt-0 text-start">
        <a href="#!">
          <h5 className="ezy__epgrid3-title">{product.title}</h5>
        </a>
        <div className="ezy__epgrid3-details d-flex justify-content-between align-items-center my-2">
          <h5 className="ezy__epgrid3-price">₹ {product.price}</h5>
        </div>
        <div>
          <Button
            onClick={() => onBuyNow(product.id)}
            className="w-100"
            style={{
              backgroundColor: "#ffc107",
              color: "black",
              border: "none",
              borderRadius: "20px",
            }}
          >
            Buy Now
          </Button>
        </div>
      </Card.Body>
    </Card>
  );
};

export default CartPage;
