import React, { useState, useEffect } from 'react';
import { Button, Form } from 'react-bootstrap';
import axios from 'axios';
import './bank.css';

function Bank() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    mobile: '',
    address: '',
    district: '',
    state: '',
    zip_code: '',
    bank_name: '',
    account_no: '',
    bank_branch: '',
    ifsc_code: '',
    password: '',
    referral_id: '',
    u_id: '',
    image: null,
  });
  const [userId, setUserId] = useState(null);
  const [userToken, setUserToken] = useState(null);
  const [useReferal, setUseReferal] = useState(null);

  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value, type, files } = e.target;
    if (type === 'file') {
      setFormData({ ...formData, [name]: files[0] });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  useEffect(() => {
    const storedUserId = localStorage.getItem("u_id");
    const storedUserToken = localStorage.getItem("user_token");
    const storedRefID = localStorage.getItem("u_ref_id");

    if (storedUserId && storedUserToken) {
      setUserId(storedUserId);
      setUserToken(storedUserToken);
      setUseReferal(storedRefID);

      console.log("Retrieved u_id:", storedUserId);
      console.log("Retrieved user_token:", storedUserToken);
      console.log("Retrieved u_ref_id:", storedRefID);
    } else {
      console.log("No user data found in localStorage.");
    }
  }, []);

  const validate = () => {
    const newErrors = {};

    if (!formData.bank_name) newErrors.bank_name = 'Bank name is required.';
    if (!formData.account_no || isNaN(formData.account_no)) {
      newErrors.account_no = 'Account number is required and must be a number.';
    }
    if (!formData.bank_branch) newErrors.bank_branch = 'Bank branch is required.';
    if (!formData.ifsc_code || formData.ifsc_code.length !== 11) {
      newErrors.ifsc_code = 'IFSC code is required and must be 11 characters.';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validate()) return;

    const data = {
      ...formData,
      u_id: userId,
      user_token: userToken,
      u_ref_id: useReferal,
    };

    // Remove the 'image' field if it's null, as we don't need to send it in the JSON
    if (!data.image) {
      delete data.image;
    }

    try {
      const response = await axios.post(
        'https://lunarsenterprises.com:6012/apnastore/edit-bank',
        JSON.stringify(data),  // Send the data as JSON
        {
          headers: {
            'Content-Type': 'application/json',  // Set the content type to application/json
            'Authorization': `Bearer ${userToken}` // Optionally include user token for authentication
          },
        }
      );

      if (response.status === 200) {
        alert('Profile updated successfully');
      } else {
        console.error('Unexpected response:', response);
        alert('An error occurred while updating the profile');
      }
    } catch (error) {
      console.error('Error updating profile:', error);
      alert('Failed to update profile. Please try again.');
    }
  };

  return (
    <>
      <div className='profile-page container'>
        <Form className="pe-md-4" noValidate onSubmit={handleSubmit}>
          <Form.Group className="mb-2 mt-2">
            <Form.Label>Bank Name</Form.Label>
            <Form.Control
              type="text"
              name="bank_name"
              placeholder="Enter Bank Name"
              required
              value={formData.bank_name}
              onChange={handleChange}
              isInvalid={!!errors.bank_name}
            />
            <Form.Control.Feedback type="invalid">
              {errors.bank_name}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="mb-2 mt-2">
            <Form.Label>Account Number</Form.Label>
            <Form.Control
              type="text"
              name="account_no"
              placeholder="Enter Account Number"
              required
              value={formData.account_no}
              onChange={handleChange}
              isInvalid={!!errors.account_no}
            />
            <Form.Control.Feedback type="invalid">
              {errors.account_no}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="mb-2 mt-2">
            <Form.Label>Bank Branch</Form.Label>
            <Form.Control
              type="text"
              name="bank_branch"
              placeholder="Enter Bank Branch"
              required
              value={formData.bank_branch}
              onChange={handleChange}
              isInvalid={!!errors.bank_branch}
            />
            <Form.Control.Feedback type="invalid">
              {errors.bank_branch}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="mb-2 mt-2">
            <Form.Label>IFSC Code</Form.Label>
            <Form.Control
              type="text"
              name="ifsc_code"
              placeholder="Enter IFSC Code"
              required
              value={formData.ifsc_code}
              onChange={handleChange}
              isInvalid={!!errors.ifsc_code}
            />
            <Form.Control.Feedback type="invalid">
              {errors.ifsc_code}
            </Form.Control.Feedback>
          </Form.Group>

          <div className="row">
            <div className="edit-button">
              <Button href="/profile" variant="secondary" type="button" className="cancel-button me-5">
                Cancel
              </Button>
              <Button variant="primary" type="submit" className="ezy__signin6-btn-submits">
                Save Changes
              </Button>
            </div>
          </div>
        </Form>
      </div>
    </>
  );
}

export default Bank;
