import React, { useEffect, useState,useRef } from "react";
import "./profile.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import moment from "moment";
import { Button, Form } from "react-bootstrap";
import {
  faCalculator,
  faCamera,
  faCopy,
  faFile,
  faHouse,
  faMoneyCheck,
  faPencil,
  faShareNodes,
  faUser,
  faUserGroup,
  faUserPlus,
  faWallet,
  faBars,
} from "@fortawesome/free-solid-svg-icons";
import avatar from "../../Assets/avatar.png";
import axios from "axios";
import { useParams } from "react-router-dom";

function Profile() {
  const [menuVisible, setMenuVisible] = useState(false);
  const [userId, setUserId] = useState(null);
  const [userToken, setUserToken] = useState(null);
  const [useReferal, setUseReferal] = useState(null);
  const [users, setUsers] = useState([]);
  const [error, setError] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const { ref_id } = useParams();

  useEffect(() => {
    const storedUserId = localStorage.getItem("u_id");
    const storedUserToken = localStorage.getItem("user_token");
    const storedRefID = localStorage.getItem("u_ref_id");

    if (storedUserId && storedUserToken) {
      setUserId(storedUserId);
      setUserToken(storedUserToken);
      setUseReferal(storedRefID);

      console.log("Retrieved u_id:", storedUserId);
      console.log("Retrieved user_token:", storedUserToken);
      console.log("Retrieved u_ref_id:", storedRefID);
    } else {
      console.log("No user data found in localStorage.");
    }
  }, []);
  const storedUserId = localStorage.getItem("u_id");

  useEffect(() => {
    const storedUserId = localStorage.getItem("u_id");
    const storedUserToken = localStorage.getItem("user_token");
    const storedRefID = localStorage.getItem("u_ref_id");
    console.log(" useEffect Retrieved u_id:", storedUserId);
    console.log("useEffect Retrieved user_token:", storedUserToken);
    console.log("useEffectRetrieved u_ref_id:", storedRefID);



    const fetchProfile = async () => {
      try {
        const storedRefID = localStorage.getItem("u_ref_id");

        const response = await axios.post(
          "https://lunarsenterprises.com:6012/apnastore/list-users",
          { ref_id: storedRefID }
        );

        setUsers(response.data.list);
        console.log("Response data:", response.data);
      } catch (error) {
        console.error("There was an error fetching the user list!", error);
        setError(error.message);
      }
    };

    fetchProfile();
  }, [ref_id]);
  console.log(users);
  const joining_date = moment(users.u_joining_date).format("YYYY-MM-DD");

  const toggleMenu = () => {
    setMenuVisible(!menuVisible);
  };

  const fileInputRef = useRef(null);

  const handleUploadClick = () => {
    // Trigger click on the hidden file input
    fileInputRef.current.click();
  };

  const handleFileChange =async (event) => {
    const file = event.target.files[0];
    console.log(userId);
    
    if (file) {
      const formData = new FormData();
      formData.append("u_id", storedUserId);
      formData.append("image", file);   
      try {
        const response = await axios.post(
          "https://lunarsenterprises.com:6012/apnastore/add-image",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
      
            },
          },
        );
        console.log("File uploaded successfully:", response.data);

      } catch (error) {
        console.error("Error uploading file:", error);
      }
      console.log("File selected:", file);
    }
  };
  console.log(" useEffect Retrieved u_id:", storedUserId);

  return (
    <div className="container">
      <Button className="menus-button d-md-none" onClick={toggleMenu}>
        <FontAwesomeIcon icon={faBars} /> Menu
      </Button>

      <div className="row">
        <div
          className={`profile-details col-md-4 ${
            menuVisible ? "d-block" : "d-none d-md-block"
          }`}
        >
          <div className="profile-page container">
            <p>
              <a className="profile-menu" href="/levelplan">
                <FontAwesomeIcon className="me-3" icon={faCalculator} />
                Dashboard
              </a>
            </p>
            <hr className="profile-break" />
            <p>
              <a className="profile-menu" href="/profile">
                <FontAwesomeIcon className="me-3" icon={faUser} />
                Profile
              </a>
            </p>
            <hr className="profile-break" />
            <p>
              <a className="profile-menu" href="/newregister">
                <FontAwesomeIcon className="me-3" icon={faUserPlus} />
                New Registration
              </a>
            </p>
            <hr className="profile-break" />
            <p>
              <a className="profile-menu" href="/treeview">
                <FontAwesomeIcon className="me-3" icon={faUserGroup} />
                Tree View
              </a>
            </p>
            <hr className="profile-break" />

            <p>
              <a className="profile-menu" href="/order">
                <FontAwesomeIcon className="me-3" icon={faFile} />
                Order History
              </a>
            </p>
            <hr className="profile-break" />
            <a className="profile-menu" href="/wallet">
              <FontAwesomeIcon className="me-3" icon={faWallet} />
              Wallet
            </a>
            <hr className="profile-break" />
            <p>
              <a className="profile-menu" href="/transaction">
                <FontAwesomeIcon className="me-3" icon={faMoneyCheck} />
                Transaction History
              </a>
            </p>
          </div>
        </div>
        <div className="col-md-8">
          <div className="profile-page container">
            <div className="profile-container">
              <div className="row mt-5">
                <img
                className="prof-image"
                  src={
                    avatar
                      ? `https://lunarsenterprises.com:6012${users[0]?.u_image}`
                      : { avatar }
                  }
                  alt="Profile"
                />
              </div>

              <div className="row mt-3">
                <Button className="btn-profile-img" onClick={handleUploadClick}>
                  Upload Photo
                </Button>
                {/* Hidden file input */}
                <input
                  type="file"
                  ref={fileInputRef}
                  style={{ display: "none" }}
                  onChange={handleFileChange}
                />
              </div>
              <div className="row">
                <h6 className="profile-name">{users[0]?.u_name}</h6>
              </div>
            </div>
            <div className="profile-updated">
              <div className="profile-bonus">
                <div className="row p-2">
                  <div className="col-6">
                    <p>User ID</p>
                  </div>
                  <div className="col-6 referral-number ">
                    <span>{users[0]?.u_ref_id}</span>
                  </div>
                </div>
              </div>
              <div className="profile-bonus">
                <div className="row p-2">
                  <div className="col-6">
                    <p>Sponser User ID</p>
                  </div>
                  <div className="col-6 referral-number ">
                    <span>{users[0]?.master_ref_id}</span>
                  </div>
                </div>
              </div>
              <div className="profile-bonus">
                <div className="row p-2">
                  <div className="col-6">
                    <p>User Status</p>
                  </div>
                  <div className="col-6 referral-number ">
                    <span>{users[0]?.u_status}</span>
                  </div>
                </div>
              </div>
              <div className="profile-bonus">
                <div className="row p-2">
                  <div className="col-6">
                    <p>Activated Date</p>
                  </div>
                  <div className="col-6 referral-number ">
                    <span>{joining_date}</span>
                  </div>
                </div>
              </div>
              {/* <div className="profile-bonus">
              <div className="row">
                <div className="col-6">
                  <p>
                    <strong>TOTAL AMOUNT</strong>
                  </p>
                </div>
                <div className="col-6 referral-number ">
                  <span>₹9999</span>
                </div>
              </div>
              <div className="row">
                <div className="col-6">
                  <p>
                    <strong>REFERRALS</strong>
                  </p>
                </div>
                <div className="col-6 referral-number ">
                  <span>5 persons joined</span>
                </div>
              </div>
              <div className="row">
                <div className="col-6">
                  <p>
                    <strong>NEXT LEVEL</strong>
                  </p>
                </div>
                <div className="col-6 referral-number ">
                  <span>Invite 5 more persons</span>
                </div>
              </div>
            </div> */}
              {/* <div></div> */}
            </div>
          </div>
          <div className="profile-page container">
            <div className="row">
              <div className="editing-info col-4">
                {" "}
                <Link to="/edit">
                  <h6 className="profile-editing">EDIT PERSONAL INFO</h6>
                </Link>
              </div>

              <div className="editing-info col-4">
                <Link to="/bankedit">
                  <h6 className="profile-editing">EDIT BANK INFO</h6>
                </Link>
              </div>
              <div className="editing-info col-4">
                <Link to="/forgetpassword">
                  <h6 className="profile-editing">CHANGE PASSWORD</h6>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Profile;
