import React, { useState } from "react";
import "./wallet.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faBars, faMoneyBillTransfer, faUser,faUserPlus,faUserGroup,faCalculator,faFile,faWallet,faMoneyCheck } from "@fortawesome/free-solid-svg-icons";
import { Button } from "react-bootstrap";


function Wallet() {
  const [menuVisible, setMenuVisible] = useState(false);

  const toggleMenu = () => {
    setMenuVisible(!menuVisible);
  };
  return (
    <div className="container wallet-container">
      <Button
        className="menus-button d-md-none" // Hide on medium and larger screens
        onClick={toggleMenu}
      >
        <FontAwesomeIcon icon={faBars} /> Menu
      </Button>
      <div className="row">
        {/* Sidebar Section */}
        <div className={`col-md-4 ${menuVisible ? 'd-block' : 'd-none d-md-block'}`}>
        <div className="profile-page container">
          <p>
              <a className="profile-menu" href="/levelplan">
                <FontAwesomeIcon className="me-3" icon={faCalculator} />
                Dashboard
              </a>
            </p>
            <hr className="profile-break" />
            <p>
              <a className="profile-menu" href="/profile">
                <FontAwesomeIcon className="me-3" icon={faUser} />
                Profile
              </a>
            </p>
            <hr className="profile-break" />
            <p>
              <a className="profile-menu" href="/newregister">
                <FontAwesomeIcon className="me-3" icon={faUserPlus} />
                New Registration
              </a>
            </p>
            <hr className="profile-break" />
            <p>
              <a className="profile-menu" href="/treeview">
                <FontAwesomeIcon className="me-3" icon={faUserGroup} />
                Tree View
              </a>
            </p>
            <hr className="profile-break" />
          
            <p>
              <a className="profile-menu" href="/order">
                <FontAwesomeIcon className="me-3" icon={faFile} />
                Order History
              </a>
            </p>
            <hr className="profile-break" />
            <a className="profile-menu" href="/wallet">
              <FontAwesomeIcon className="me-3" icon={faWallet} />
              Wallet
            </a>
            <hr className="profile-break" />
            <p>
              <a className="profile-menu" href="/transaction">
                <FontAwesomeIcon className="me-3" icon={faMoneyCheck} />
                Transaction History
              </a>
            </p>
          </div>
        </div>

        {/* Main Wallet Section */}
        <div className="col-md-8">
        <div className="profile-page container">
        <div className="profiles-page bg-white shadow-sm rounded p-4">
  <div className="wallet-design pt-3 text-center">
    <h5 className="wallet-earned">
      Your earned <span className="wallet-amount">₹9999</span>
    </h5>
  </div>
  <div className="row pt-4 align-items-center justify-content-center">
    <div className="col-md-6 col-sm-12 text-center mb-3">
      <h6 className="wallet-earning font-weight-bold">Your Balance</h6>
      <div className="wallet-amounts display-4">₹80</div>
    </div>
    <div className="col-md-6 col-sm-12 text-center">
    <div className="money-icons" style={{ display: 'flex', alignItems: 'center' }}>
  <FontAwesomeIcon
    icon={faMoneyBillTransfer}
    className="text-success"
    style={{
      fontSize: "4rem",
      padding: "15px",
      backgroundColor: "#f1f9f5",
      borderRadius: "50%"
    }}
  />
  <span style={{ marginLeft: '10px', fontSize: '1.5rem' }}>
    Transfer
  </span>
</div>

    </div>
  </div>
</div>

        </div>
        </div>
      </div>
    </div>
  );
}

export default Wallet;
