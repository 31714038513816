import React, { Fragment, useState } from "react";
import { Button, Card, Col, Container, Form, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebook, faGoogle } from "@fortawesome/free-brands-svg-icons";
import "./register.css";
import kapna from "../../Assets/kapna-round.png";
import axios from "axios"; 
import Select from 'react-select';
import IndianStatesCitiesList from "indian-states-cities-list";
import { useNavigate } from "react-router";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';



// const SocialLoginButton = () => (
// 	<Fragment>
// 		<Button
// 			variant="primary"
// 			className="ezy__signin6-btn w-100 d-flex align-items-center mb-3"
// 		>
// 			<span className="text-white fs-4 lh-1">
// 				<FontAwesomeIcon icon={faFacebook} />
// 			</span>
// 			<span className="w-100 text-center text-white">
// 				Continue with Facebook
// 			</span>
// 		</Button>
// 		<Button
// 			variant="danger"
// 			className="ezy__signin6-btn w-100 d-flex align-items-center"
// 		>
// 			<span className="text-white fs-4 lh-1">
// 				<FontAwesomeIcon icon={faGoogle} />
// 			</span>
// 			<span className="w-100 text-center text-white">Continue with Google</span>
// 		</Button>
// 	</Fragment>
// );

const SignInForm = () => {
  const [validated, setValidated] = useState(false);
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    mobile: "",
    address: "",
    district: "",
    state: "",
    zip_code: "",
    bank_name: "",
    account_no: "",
    bank_branch: "",
    ifsc_code: "",
    password: "",
    referral_id: ""
  });
  const [districtOptions, setDistrictOptions] = useState([]);
  const statesList = IndianStatesCitiesList.STATES_OBJECT;

  const handleStateChange = (selectedState) => {
    setFormData({ ...formData, state: selectedState.value });
    setDistrictOptions(IndianStatesCitiesList.STATE_WISE_CITIES[selectedState.value]?.map(city => ({
      value: city.label,
      label: city.label,
    })) || []);
  };

  const handleDistrictChange = (selectedDistrict) => {
    setFormData({ ...formData, district: selectedDistrict.value });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    
    if (form.checkValidity() === false) {
      event.stopPropagation();
    } else {
      try {
        const formDataToSend = new FormData();
        Object.keys(formData).forEach(key => {
          formDataToSend.append(key, formData[key]);
        });
        
        const response = await axios.post(
          'https://lunarsenterprises.com:6012/apnastore/user-registration',
          formDataToSend,
          {
            headers: { 'Content-Type': 'multipart/form-data' }
          }
        );
  
        if(response.data.result) {
          toast.success(response.data.message);
          navigate('/login');
        } else {
          toast.info(response.data.message);
        }
      } catch (error) {
        console.error("Error registering user:", error);
        toast.error('Something went wrong. Please try again later');
      }
    }
    setValidated(true);
  };
  return (
    <Form noValidate validated={validated} onSubmit={handleSubmit}>
      <Form.Group className="mb-2 mt-2">
        <Form.Label>Name</Form.Label>
        <Form.Control 
          type="text" 
          placeholder="Enter Your Name" 
          name="name"
          required 
          onChange={handleChange} 
        />
        <Form.Control.Feedback type="invalid">
          Please enter your name.
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group className="mb-2 mt-2">
        <Form.Label>Email Address</Form.Label>
        <Form.Control 
          type="email" 
          placeholder="Enter Email Address" 
          name="email"
          required 
          onChange={handleChange} 
        />
        <Form.Control.Feedback type="invalid">
          Please enter a valid email address.
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group className="mb-2 mt-2">
        <Form.Label>Mobile</Form.Label>
        <Form.Control 
          type="tel" 
          placeholder="Enter Mobile Number" 
          name="mobile"
          required 
          onChange={handleChange} 
        />
        <Form.Control.Feedback type="invalid">
          Please enter a valid mobile number.
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group className="mb-2 mt-2">
        <Form.Label>Address</Form.Label>
        <Form.Control 
          type="text" 
          placeholder="Enter Your Address" 
          name="address"
          required 
          onChange={handleChange} 
        />
        <Form.Control.Feedback type="invalid">
          Please enter your house name.
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group className="mb-2 mt-2">
        <Form.Label>State</Form.Label>
        <Select
          options={statesList}
          placeholder="Select State"
          name="state"
          isClearable
          onChange={handleStateChange}
          className="form-control p-0"
          styles={{
            control: (base) => ({
              ...base,
              borderColor: "#ced4da",
              boxShadow: "none",
            }),
          }}
        />
      </Form.Group>

      <Form.Group className="mb-2 mt-2">
        <Form.Label>District</Form.Label>
        <Select
          options={districtOptions}
          placeholder="Select District"
          name="district"
          isClearable
          onChange={handleDistrictChange}
          className="form-control p-0"
          styles={{
            control: (base) => ({
              ...base,
              borderColor: "#ced4da",
              boxShadow: "none",
            }),
          }}
        />
      </Form.Group>

      <Form.Group className="mb-2 mt-2">
        <Form.Label>Zip Code</Form.Label>
        <Form.Control 
          type="number" 
          placeholder="Enter Pincode" 
          name="zip_code"
          required 
          onChange={handleChange} 
        />
        <Form.Control.Feedback type="invalid">
          Please enter your zip_code.
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group className="mb-2 mt-2">
        <Form.Label>Bank Name</Form.Label>
        <Form.Control 
          type="text" 
          placeholder="Enter Bank name" 
          name="bank_name"
          required 
          onChange={handleChange} 
        />
        <Form.Control.Feedback type="invalid">
          Please enter bank name.
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group className="mb-2 mt-2">
        <Form.Label>Account Number</Form.Label>
        <Form.Control 
          type="text" 
          placeholder="Enter Account Number" 
          name="account_no"
          required 
          onChange={handleChange} 
        />
        <Form.Control.Feedback type="invalid">
          Please enter your account number.
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group className="mb-2 mt-2">
        <Form.Label>Bank Branch</Form.Label>
        <Form.Control 
          type="text" 
          placeholder="Enter Bank Branch" 
          name="bank_branch"
          required 
          onChange={handleChange} 
        />
        <Form.Control.Feedback type="invalid">
          Please enter your bank branch.
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group className="mb-2 mt-2">
        <Form.Label>IFSC Code</Form.Label>
        <Form.Control 
          type="text" 
          placeholder="Enter IFSC Code" 
          name="ifsc_code"
          required 
          onChange={handleChange} 
        />
        <Form.Control.Feedback type="invalid">
          Please enter a valid IFSC Code.
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group className="mb-2 mt-2">
        <Form.Label>Password</Form.Label>
        <Form.Control 
          type="password" 
          placeholder="Enter Password" 
          name="password"
          required 
          onChange={handleChange} 
        />
        <Form.Control.Feedback type="invalid">
          Please enter a password.
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group className="mb-2 mt-2">
        <Form.Label>Referral ID</Form.Label>
        <Form.Control 
          type="text" 
          placeholder="Enter Referral ID" 
          name="referral_id"
          onChange={handleChange} 
        />
         <Form.Control.Feedback type="invalid">
          Please enter Referral ID.
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Check
          type="checkbox"
          id="signin6-remember-me"
          label="Remember me"
        />
      </Form.Group>

      <Button
        variant="primary"
        type="submit"
        className="registration-button w-100"
      >
        Register
      </Button>

      <p className="mb-4 mb-md-5">
        <span className="mb-0 opacity-50 lh-1">Already have an account?</span>
        <Button
        href="/login"
          variant="link"
          className="log-button text-decoration-none mb-0"
          onClick={() => console.log("Redirect to Login Page")}
        >
          Login
        </Button>
      </p>

      {/* <div className="divider-text">OR</div> */}
      {/* <div className="social-icons">
        <Button className="mb-2" variant="outline-secondary">
          <FontAwesomeIcon icon={faFacebook} />
        </Button>
        <Button variant="outline-secondary">
          <FontAwesomeIcon icon={faGoogle} />
        </Button>
      </div> */}
       <ToastContainer />
    </Form>
  );
};

const SignInFormCard = () => (
  <div className="ezy__signin6-form-card">
    <Card.Body className="p-0">
      <div className="logo-kapna">
      <img className="image-logo" src={kapna} alt="" />
      </div>
      {/* <h2 className="ezy__signin6-heading mb-3">Welcome to Easy Frontend</h2> */}
    

      <SignInForm />

      {/* <div className="position-relative ezy__signin6-or-separator">
				<hr className="my-4 my-md-5" />
				<span className="px-2">Or</span>
			</div> */}

      {/* <SocialLoginButton /> */}
    </Card.Body>
  </div>
);

const SignIn6 = () => {
  return (
    <section className="ezy__signin6 light d-flex">
      <Container>
        <Row className="justify-content-between h-100">
          <Col lg={6}>
            <div
              className="ezy__signin6-bg-holder d-none d-lg-block"
              style={{
                backgroundImage:
                  "url(https://www.naatigrains.com/image/cache/catalog/naatigrains-products/NG204/puruvi-premium-quality-ctc-tea-natural-powder-black-naati-grains-1000x1000.jpg)",
              }}
            />
          </Col>
          <Col lg={5} className="py-5">
            <Row className="align-items-center">
              <Col xs={12}>
                <SignInFormCard />
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
     
    </section>
  );
};
export default SignIn6;
