import React, { useState, useEffect } from "react";
import { Button, Card, Col, Container, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShoppingCart, faHeart } from "@fortawesome/free-solid-svg-icons";
import PropTypes from "prop-types";
import placeholderImg from "../../Assets/bag.png";
import { useNavigate } from "react-router";
import axios from "axios";
import "./wishlist.css";

// Product Item Component
const ProductItem = ({ product, isFavorite, onBuyNow, onToggleFavorite }) => (
  <Card className="ezy__epgrid3-card position-relative p-2">
    <a
      href="#!"
      aria-label="Add to favorites"
      onClick={(e) => {
        e.preventDefault(); // Prevent the default anchor behavior
        onToggleFavorite(product.p_id, isFavorite ? 0 : 1); // Toggle favorite status
      }}
    >
      <div className="ezy__epgrid3-fav-icon">
        <FontAwesomeIcon icon={faHeart} color={isFavorite ? "red" : "gray"} />
      </div>
    </a>
    <Card.Body>
      <div className="ezy__epgrid3-card-img d-flex justify-content-center align-items-center h-100 px-4">
        <img
          src={
            product.p_image
              ? `https://lunarsenterprises.com:6012${product.p_image}`
              : placeholderImg
          }
          alt={product.p_name}
        />
      </div>
    </Card.Body>
    <Card.Body className="h-100 pt-0 text-start">
      <a href="#!">
        <h5 className="ezy__epgrid3-title">{product.p_name}</h5>
      </a>
      <div className="ezy__epgrid3-details d-flex justify-content-between align-items-center my-2">
        <h5 className="ezy__epgrid3-price">₹ {product.p_price}</h5>
      </div>
      <div>
        <Button
          onClick={() => onBuyNow(product.p_id)}
          className="w-100"
          style={{
            backgroundColor: "#ffc107",
            color: "black",
            border: "none",
            borderRadius: "20px",
          }}
        >
          Buy Now
        </Button>
      </div>
    </Card.Body>
  </Card>
);

ProductItem.propTypes = {
  product: PropTypes.shape({
    p_image: PropTypes.string,
    p_name: PropTypes.string.isRequired,
    p_price: PropTypes.string.isRequired,
    p_id: PropTypes.number.isRequired,
  }).isRequired,
  isFavorite: PropTypes.bool.isRequired,
  onBuyNow: PropTypes.func.isRequired,
  onToggleFavorite: PropTypes.func.isRequired,
};

// Epgrid3 Component (main component)
const Epgrid3 = () => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const [userId, setUserId] = useState(null);
  const [favorites, setFavorites] = useState({});

  // Fetch user info from localStorage
  useEffect(() => {
    const storedUserId = localStorage.getItem("u_id");
    const storedUserToken = localStorage.getItem("user_token");

    if (storedUserId && storedUserToken) {
      setUserId(storedUserId);
    }
  }, []);

  // Fetch favorite products from the API
  useEffect(() => {
    if (userId) {
      const fetchFavoriteProducts = async () => {
        try {
          const response = await axios.post(
            "https://lunarsenterprises.com:6012/apnastore/list-favorites",
            { u_id: userId }
          );

          if (response.data && response.data.result && Array.isArray(response.data.data)) {
            setProducts(response.data.data);
            const favoriteStatus = response.data.data.reduce((acc, product) => {
              acc[product.p_id] = true;
              return acc;
            }, {});
            setFavorites(favoriteStatus);
          } else {
            setError("No products found.");
          }
        } catch (err) {
          setError("Failed to fetch favorites");
        } finally {
          setLoading(false);
        }
      };

      fetchFavoriteProducts();
    }
  }, [userId]);

  const handleBuyNow = (p_id) => {
    navigate(`/selection/${p_id}`);
  };

  const handleToggleFavorite = async (productId, favStatus) => {
    if (!userId) {
      console.log("No user ID found. Please log in first.");
      return;
    }
    try {
      const response = await axios.post(
        "https://lunarsenterprises.com:6012/apnastore/add-favorites",
        {
          fav: favStatus,
          user_id: userId,
          p_id: productId,
        }
      );
      if (response.status === 200) {
        setFavorites((prevFavorites) => ({
          ...prevFavorites,
          [productId]: favStatus === 1,
        }));
        setProducts((prevProducts) =>
          prevProducts.filter((product) => product.p_id !== productId || favStatus === 1)
        );
      }
    } catch (error) {
      console.error("Error adding product to favorites:", error);
    }
  };

  return (
    <section className="ezy__epgrid3 light">
      <Container>
        <Row className="ezy__epgrid3-row text-center justify-content-xl-center mt-5">
          {loading ? (
            <p>Loading products...</p>
          ) : error ? (
            <p style={{ color: "red" }}>{error}</p>
          ) : products.length > 0 ? (
            products.map((product) => (
              <Col md={6} lg={4} xl={3} className="px-2 my-2" key={product.p_id}>
                <ProductItem
                  product={product}
                  isFavorite={!!favorites[product.p_id]}
                  onBuyNow={handleBuyNow}
                  onToggleFavorite={handleToggleFavorite}
                />
              </Col>
            ))
          ) : (
            <p style={{ fontWeight: "bolder", color: "black", fontSize: "1rem" }}>
              No products available
            </p>
          )}
        </Row>
      </Container>
    </section>
  );
};

export default Epgrid3;
