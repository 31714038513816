import React, { useState ,useEffect} from "react";
import "./levelplan.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalculator,
  faFile,
  faBars,
  faMoneyCheck,
  faUser,
  faUserGroup,
  faUserPlus,
  faWallet,
} from "@fortawesome/free-solid-svg-icons";
import kapna from "../../Assets/kapna-round.png";
import { Button } from "react-bootstrap";
import axios from "axios";
import { useParams } from "react-router-dom";
import moment from "moment";

function Levelplan() {
  const [menuVisible, setMenuVisible] = useState(false);
  const [userId, setUserId] = useState(null);
  const [userToken, setUserToken] = useState(null);
  const [useReferal, setUseReferal] = useState(null);
  const [users, setUsers] = useState([]);
  const [error, setError] = useState(null);
  const { ref_id } = useParams();

  useEffect(() => {
    const storedUserId = localStorage.getItem("u_id");
    const storedUserToken = localStorage.getItem("user_token");
    const storedRefID = localStorage.getItem("u_ref_id");

    if (storedUserId && storedUserToken) {
      setUserId(storedUserId);
      setUserToken(storedUserToken);
      setUseReferal(storedRefID);

      console.log("Retrieved u_id:", storedUserId);
      console.log("Retrieved user_token:", storedUserToken);
      console.log("Retrieved u_ref_id:", storedRefID);
    } else {
      console.log("No user data found in localStorage.");
    }
  }, []);

  useEffect(() => {
    const storedUserId = localStorage.getItem("u_id");
    const storedUserToken = localStorage.getItem("user_token");
    const storedRefID = localStorage.getItem("u_ref_id");
    console.log(" useEffect Retrieved u_id:", storedUserId);
    console.log("useEffect Retrieved user_token:", storedUserToken);
    console.log("useEffectRetrieved u_ref_id:", storedRefID);

    const fetchProfile = async () => {
      try {
        const storedRefID = localStorage.getItem("u_ref_id");

        const response = await axios.post(
          "https://lunarsenterprises.com:6012/apnastore/list-users",
          { ref_id: storedRefID }
        );

        setUsers(response.data.list);
        console.log("Response data:", response.data);
      } catch (error) {
        console.error("There was an error fetching the user list!", error);
        setError(error.message);
      }
    };
    fetchProfile();
  }, [ref_id]);
  console.log(users);
  const joining_date = moment(users.u_joining_date).format("YYYY-MM-DD");

  const toggleMenu = () => {
    setMenuVisible(!menuVisible);
  };
   const referralCode = 'ABC123XYZ';

  const [copySuccess, setCopySuccess] = useState('');

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(referralCode);
      setCopySuccess('Copied!');
    } catch (err) {
      setCopySuccess('Failed to copy!');
    }
    setTimeout(() => setCopySuccess(''), 2000); // Clear message after 2 seconds
  };
  return (
    <>
      <div className="container">
        <Button
          className="menus-button d-md-none" // Hide on medium and larger screens
          onClick={toggleMenu}
        >
          <FontAwesomeIcon icon={faBars} /> Menu
        </Button>
        <div className="row">
          <div
            className={`col-md-4 ${
              menuVisible ? "d-block" : "d-none d-md-block"
            }`}
          >
            <div className="profile-page container">
          <p>
              <a className="profile-menu" href="/levelplan">
                <FontAwesomeIcon className="me-3" icon={faCalculator} />
                Dashboard
              </a>
            </p>
            <hr className="profile-break" />
            <p>
              <a className="profile-menu" href="/profile">
                <FontAwesomeIcon className="me-3" icon={faUser} />
                Profile
              </a>
            </p>
            <hr className="profile-break" />
            <p>
              <a className="profile-menu" href="/newregister">
                <FontAwesomeIcon className="me-3" icon={faUserPlus} />
                New Registration
              </a>
            </p>
            <hr className="profile-break" />
            <p>
              <a className="profile-menu" href="/treeview">
                <FontAwesomeIcon className="me-3" icon={faUserGroup} />
                Tree View
              </a>
            </p>
            <hr className="profile-break" />
          
            <p>
              <a className="profile-menu" href="/order">
                <FontAwesomeIcon className="me-3" icon={faFile} />
                Order History
              </a>
            </p>
            <hr className="profile-break" />
            <a className="profile-menu" href="/wallet">
              <FontAwesomeIcon className="me-3" icon={faWallet} />
              Wallet
            </a>
            <hr className="profile-break" />
            <p>
              <a className="profile-menu" href="/transaction">
                <FontAwesomeIcon className="me-3" icon={faMoneyCheck} />
                Transaction History
              </a>
            </p>
          </div>
          </div>
          <div className="col-md-8">
            <div className="profile-page container">
              <div className="profile-view row">
                <img className="logo-image" src={kapna} alt="" />
                <h6>{users[0]?.u_ref_id}</h6>
                <h6>{users[0]?.u_name}</h6>
                <div className="profile-ranking container">
                  <div className=" row">
                    <p className="text-black">YOUR RANK</p>
                    <h5 className="profile-position" style={{ textTransform: 'uppercase' }}>{users[0]?.u_position}</h5>
                  </div>
                </div>
                <div className="sponser-details container">
                  <div className="row">
                    <div className="col-6">
                      <h6 className="sponser-id">Sponser ID</h6>
                    </div>
                    <div className="col-6">
                      <h6 className="joining-date">Join Date</h6>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-6">
                      <h6 className="sponser-id">{users[0]?.master_ref_id}</h6>
                    </div>
                    <div className="col-6">
                      <h6 className="joining-date">{joining_date}</h6>
                    </div>
                  </div>
                </div>

                <div className="activation-status container">
                  <div className="row">
                    <div className="col-6">
                      <h6 className="sponser-id">Activation Status</h6>
                    </div>
                    <div className="col-6">
                      <h6 className="joining-date">Date of Activation</h6>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-6">
                      <h6 className="sponser-id">{users[0]?.u_status}</h6>
                    </div>
                    <div className="col-6">
                      <h6 className="joining-date">12-1-2024</h6>
                    </div>
                  </div>
                </div>
                <div className="profile-referals container">
                  <h6 className="sponser-id">Direct Referrals</h6>
                  <h6 className="sponser-id">0</h6>
                </div>
                <div className="profile-referals container">
                  <h6 className="sponser-id">Active Referrals</h6>
                  <h6 className="sponser-id">0</h6>
                </div>
                <div className="profile-referals container">
                  <h6 className="sponser-id">Total Sales</h6>
                  <h6 className="sponser-id">2940</h6>
                </div>
                <div className="profile-referals container">
                  <h6 className="sponser-id">Today Commission Earned</h6>
                  <h6 className="sponser-id">0</h6>
                </div>
                <div className="profile-referals container">
                  <h6 className="sponser-id">Total Commission Earned</h6>
                  <h6 className="sponser-id">0</h6>
                </div>
                <div className="profile-referals container">
                  <h6 className="sponser-id">Wallet Balance</h6>
                  <h6 className="sponser-id">0</h6>
                </div>
              </div>
            </div>
            <div  className="profile-page container">
              <h6 className="profile-contents">Share Your Link</h6>
              <p className="profile-contents">Copy your Personal Referal Link And Share It</p>
              {/* <h6  className="profile-contents">Referral Link</h6> */}


              <div className="copy-link">
              <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
      <p style={{ fontWeight: 'bold', margin: 0 }}>Referral Code: {referralCode}</p>
      <button onClick={handleCopy} style={{ padding: '5px 10px', cursor: 'pointer' }}>
        Copy
      </button>
      {copySuccess && <span>{copySuccess}</span>}
    </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Levelplan;
