import React, { useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import axios from 'axios';
import './edit.css';

function Edit() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    mobile: '',
    address: '',
    district: '',
    state: '',
    zip_code: '',
    password: '',
    referral_id: '',
    u_id: '',
    image: null, 
  });

  const [errors, setErrors] = useState({});

  // Validate inputs
  const validate = () => {
    const newErrors = {};
    const nameRegex = /^[A-Za-z\s]+$/; // Name should contain only alphabets and spaces
    const mobileRegex = /^[0-9]{10}$/; // Mobile should be 10 digits
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/; // Valid email format
    const passwordRegex = /.{6,}/; // Password should be at least 6 characters
    const zipRegex = /^[0-9]{6}$/; // Pincode should be 6 digits

    if (!formData.name || !nameRegex.test(formData.name)) {
      newErrors.name = 'Please enter a valid name (only alphabets and spaces)';
    }
    if (!formData.email || !emailRegex.test(formData.email)) {
      newErrors.email = 'Please enter a valid email address';
    }
    if (!formData.mobile || !mobileRegex.test(formData.mobile)) {
      newErrors.mobile = 'Please enter a valid 10-digit mobile number';
    }
    if (!formData.zip_code || !zipRegex.test(formData.zip_code)) {
      newErrors.zip_code = 'Please enter a valid 6-digit pincode';
    }
    if (!formData.password || !passwordRegex.test(formData.password)) {
      newErrors.password = 'Password must be at least 6 characters';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleChange = (e) => {
    const { name, value, type, files } = e.target;
    if (type === 'file') {
      setFormData({ ...formData, [name]: files[0] });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate form data before submitting
    if (!validate()) {
      return;
    }

    console.log("Form Data:", formData);

    const data = new FormData();
    for (const key in formData) {
      if (formData[key]) {
        data.append(key, formData[key]);
      }
    }

    try {
      const response = await axios.post(
        'https://lunarsenterprises.com:6012/apnastore/edit-profile',
        data, 
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );

      if (response.status === 200) {
        alert('Profile updated successfully');
      } else {
        console.error('Unexpected response:', response);
        alert('An error occurred while updating the profile');
      }
    } catch (error) {
      console.error('Error updating profile:', error);
      alert('Failed to update profile. Please try again.');
    }
  };

  return (
    <>
      <div className="profile-page container">
        <Form className="pe-md-4" noValidate onSubmit={handleSubmit}>
          <Form.Group className="mb-2 mt-2">
            <Form.Label>Name</Form.Label>
            <Form.Control
              type="text"
              name="name"
              placeholder="Enter Your Name"
              required
              value={formData.name}
              onChange={handleChange}
            />
            {errors.name && <div className="text-danger">{errors.name}</div>}
          </Form.Group>

          <Form.Group className="mb-2 mt-2">
            <Form.Label>Mobile</Form.Label>
            <Form.Control
              type="tel"
              name="mobile"
              placeholder="Enter Mobile Number"
              required
              value={formData.mobile}
              onChange={handleChange}
            />
            {errors.mobile && <div className="text-danger">{errors.mobile}</div>}
          </Form.Group>

          <Form.Group className="mb-2 mt-2">
            <Form.Label>Email Address</Form.Label>
            <Form.Control
              type="email"
              name="email"
              placeholder="Enter Email Address"
              required
              value={formData.email}
              onChange={handleChange}
            />
            {errors.email && <div className="text-danger">{errors.email}</div>}
          </Form.Group>

          <Form.Group className="mb-2 mt-2">
            <Form.Label>Address</Form.Label>
            <Form.Control
              type="text"
              name="address"
              placeholder="Enter Address"
              required
              value={formData.address}
              onChange={handleChange}
            />
          </Form.Group>

          <Form.Group className="mb-2 mt-2">
            <Form.Label>District</Form.Label>
            <Form.Control
              type="text"
              name="district"
              placeholder="Enter District"
              required
              value={formData.district}
              onChange={handleChange}
            />
          </Form.Group>

          <Form.Group className="mb-2 mt-2">
            <Form.Label>State</Form.Label>
            <Form.Control
              type="text"
              name="state"
              placeholder="Enter State"
              required
              value={formData.state}
              onChange={handleChange}
            />
          </Form.Group>

          <Form.Group className="mb-2 mt-2">
            <Form.Label>Pincode</Form.Label>
            <Form.Control
              type="number"
              name="zip_code"
              placeholder="Enter Pincode"
              required
              value={formData.zip_code}
              onChange={handleChange}
            />
            {errors.zip_code && <div className="text-danger">{errors.zip_code}</div>}
          </Form.Group>

          <Form.Group className="mb-2 mt-2">
            <Form.Label>Password</Form.Label>
            <Form.Control
              type="password"
              name="password"
              placeholder="Enter Password"
              required
              value={formData.password}
              onChange={handleChange}
            />
            {errors.password && <div className="text-danger">{errors.password}</div>}
          </Form.Group>

          <Form.Group className="mb-2 mt-2">
            <Form.Label>Referral ID</Form.Label>
            <Form.Control
              type="text"
              name="referral_id"
              placeholder="Enter Referral ID"
              required
              value={formData.referral_id}
              onChange={handleChange}
            />
          </Form.Group>

          <div className="row">
            <div className="edit-button">
              <Button href="/profile" variant="" type="button" className="cancel-button me-5">
                Cancel
              </Button>
              <Button variant="" type="submit" className="ezy__signin6-btn-submits">
                Save Changes
              </Button>
            </div>
          </div>
        </Form>
      </div>
    </>
  );
}

export default Edit;
