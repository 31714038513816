import React, { useState } from 'react';
import { Button, Form, Alert } from 'react-bootstrap';
import axios from 'axios';
import './change.css';

function Change() {
  const [formData, setFormData] = useState({
    email: '',
    password: '',
    confirmPassword: '',
    role: 'user'
  });
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [confirmPasswordError, setConfirmPasswordError] = useState('');
  const [showPassword, setShowPassword] = useState(false); // New state for password visibility

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    if (name === 'password' || name === 'confirmPassword') {
      setPasswordError('');
      setConfirmPasswordError('');
    } else if (name === 'email') {
      setEmailError('');
    }
  };

  const isPasswordStrong = (password) => {
    const strongPasswordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    return strongPasswordRegex.test(password);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setSuccess(false);

    if (!formData.email) {
      setEmailError('Email is required.');
      return;
    }
    if (!formData.password) {
      setPasswordError('Password is required.');
      return;
    }
    if (!isPasswordStrong(formData.password)) {
      setPasswordError(
        'Password must be at least 8 characters long, include uppercase and lowercase letters, a number, and a special character.'
      );
      return;
    }
    if (formData.password !== formData.confirmPassword) {
      setConfirmPasswordError('Passwords do not match.');
      return;
    }

    try {
      const response = await axios.post(
        'https://lunarsenterprises.com:6012/apnastore/change-password',
        formData,
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      );

      if (response.status === 200) {
        setSuccess(true);
        setFormData({
          email: '',
          password: '',
          confirmPassword: '',
          role: 'user'
        });
      } else {
        setError('Failed to change password. Please try again.');
      }
    } catch (err) {
      setError('An error occurred. Please check your network and try again.');
    }
  };

  return (
    <div className="profile-page container">
      <Form className="pe-md-4" noValidate onSubmit={handleSubmit}>
        {success && <Alert variant="success">Password changed successfully!</Alert>}
        {error && <Alert variant="danger">{error}</Alert>}
        
        <Form.Group className="mb-2 mt-2">
          <Form.Label>Password</Form.Label>
          <Form.Control
            type={showPassword ? 'text' : 'password'}
            name="password"
            placeholder="Enter password"
            required
            value={formData.password}
            onChange={handleChange}
            isInvalid={!!passwordError}
          />
          <Form.Control.Feedback type="invalid">{passwordError}</Form.Control.Feedback>
        </Form.Group>

        <Form.Group className="mb-2 mt-2">
          <Form.Label>Confirm Password</Form.Label>
          <Form.Control
            type={showPassword ? 'text' : 'password'}
            name="confirmPassword"
            placeholder="Confirm password"
            required
            value={formData.confirmPassword}
            onChange={handleChange}
            isInvalid={!!confirmPasswordError}
          />
          <Form.Control.Feedback type="invalid">{confirmPasswordError}</Form.Control.Feedback>
        </Form.Group>

        {/* Checkbox to toggle password visibility */}
        <Form.Group className="mb-2">
          <Form.Check 
            type="checkbox" 
            label="Show Password" 
            checked={showPassword} 
            onChange={() => setShowPassword(!showPassword)} 
          />
        </Form.Group>

        <div className="row">
          <div className="edit-button">
            <Button href="/" variant="" type="button" className="cancel-button me-5">
              Cancel
            </Button>
            <Button href="/login" variant="" type="submit" className="ezy__signin6-btn-submits">
              Save
            </Button>
          </div>
        </div>
      </Form>
    </div>
  );
}

export default Change;
