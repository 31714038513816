import React from 'react';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import './insurance.css';
import tea from '../../Assets/tea.pdf';

function Insurance() {
  return (
    <div className="insurance-container">
      <h1 className='insurance-heading'> Our Insurance</h1>
      <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
        <div className="pdf-viewer">
          <Viewer fileUrl={tea} />
        </div>
      </Worker>
    </div>
  );
}

export default Insurance;
