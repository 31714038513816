import React, { useState } from 'react';
import { Button, Form, Alert } from 'react-bootstrap';
import axios from 'axios';
import './lchange.css'

function Lchange() {
    const [formData, setFormData] = useState({
        email: '',
        password: '',
        role: 'user'
      });
      const [success, setSuccess] = useState(false);
      const [error, setError] = useState('');
      const [passwordError, setPasswordError] = useState('');
      const [emailError, setEmailError] = useState('');
    
      const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    
        // Reset specific errors when input changes
        if (name === 'password') {
          setPasswordError('');
        } else if (name === 'email') {
          setEmailError('');
        }
      };
    
      const isPasswordStrong = (password) => {
        // Check if password has at least 8 characters, including uppercase, lowercase, numbers, and special characters
        const strongPasswordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
        return strongPasswordRegex.test(password);
      };
    
      const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');
        setSuccess(false);
    
        // Basic validation
        if (!formData.email) {
          setEmailError('Email is required.');
          return;
        }
        if (!formData.password) {
          setPasswordError('Password is required.');
          return;
        }
        if (!isPasswordStrong(formData.password)) {
          setPasswordError(
            'Password must be at least 8 characters long, include uppercase and lowercase letters, a number, and a special character.'
          );
          return;
        }
    
        try {
          const response = await axios.post(
            'https://lunarsenterprises.com:6012/apnastore/change-password',
            formData,
            {
              headers: {
                'Content-Type': 'application/json'
              }
            }
          );
    
          if (response.status === 200) {
            setSuccess(true);
            setFormData({
              email: '',
              password: '',
              role: 'user'
            });
          } else {
            setError('Failed to change password. Please try again.');
          }
        } catch (err) {
          setError('An error occurred. Please check your network and try again.');
        }
      };
    
  return (
    <>
       <div className='change-password'>
    <div className="profile-page container">
   
      <Form className="pe-md-4" noValidate onSubmit={handleSubmit}>
        {success && <Alert variant="success">Password changed successfully!</Alert>}
        {error && <Alert variant="danger">{error}</Alert>}
        {/* <Form.Group className="mb-2 mt-2">
          <Form.Label>Email Address</Form.Label>
          <Form.Control
            type="email"
            name="email"
            placeholder="Enter Email Address"
            required
            value={formData.email}
            onChange={handleChange}
            isInvalid={!!emailError}
          />
          <Form.Control.Feedback type="invalid">{emailError}</Form.Control.Feedback>
        </Form.Group> */}
        <Form.Group className="mb-2 mt-2">
          <Form.Label>Password</Form.Label>
          <Form.Control
            type="password"
            name="password"
            placeholder="Enter password"
            required
            value={formData.password}
            onChange={handleChange}
            isInvalid={!!passwordError}
          />
          <Form.Control.Feedback type="invalid">{passwordError}</Form.Control.Feedback>
        </Form.Group>


        <Form.Group className="mb-2 mt-2">
          <Form.Label> Confirm Password</Form.Label>
          <Form.Control
            type="password"
            name="password"
            placeholder="Confirm password"
            required
            value={formData.password}
            onChange={handleChange}
            isInvalid={!!passwordError}
          />
          <Form.Control.Feedback type="invalid">{passwordError}</Form.Control.Feedback>
        </Form.Group>


        <div className="row">
          <div className="edit-button">
            <Button href="/profile" variant="" type="button" className="cancel-button me-5">
              Cancel
            </Button>
            <Button  href="/login"variant="" type="submit" className="ezy__signin6-btn-submits">
              Save
            </Button>
          </div>
        </div>
      </Form>
    </div>
    </div></>
  )
}

export default Lchange