import React, { useState,useEffect } from "react";
import './transaction.css';
import { Button, Table } from 'react-bootstrap';
import { faCalculator, faFile, faBars, faMoneyCheck, faUser, faUserGroup, faUserPlus, faWallet } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from "axios";



function Transaction() {
  const [menuVisible, setMenuVisible] = useState(false);
  const [userId, setUserId] = useState(null);
  const [userToken, setUserToken] = useState(null);
  const [useReferal, setUseReferal] = useState(null);
  const[transaction,setTransaction]=useState([])

  const toggleMenu = () => {
    setMenuVisible(!menuVisible);
  };

  useEffect(() => {
    const storedUserId = localStorage.getItem("u_id");
    const storedUserToken = localStorage.getItem("user_token");
    const storedRefID = localStorage.getItem("u_ref_id");

    if (storedUserId && storedUserToken) {
      setUserId(storedUserId);
      setUserToken(storedUserToken);
      setUseReferal(storedRefID);

      console.log("Retrieved u_id:", storedUserId);
      console.log("Retrieved user_token:", storedUserToken);
      console.log("Retrieved u_ref_id:", storedRefID);
    } else {
      console.log("No user data found in localStorage.");
    }
  }, []);
 

  useEffect(() => {
    const storedUserId = localStorage.getItem("u_id");
      const storedUserToken = localStorage.getItem("user_token");
      const storedRefID = localStorage.getItem("u_ref_id");
      console.log(" useEffect Retrieved u_id:", storedUserId);
      console.log("useEffect Retrieved user_token:", storedUserToken);
      console.log("useEffectRetrieved u_ref_id:", storedRefID);
  
      const fetchTreeView = async () => {
        try {
          const response = await axios.post("https://lunarsenterprises.com:6012/apnastore/list-order", { u_id: storedUserId });
          console.log(response);
          setTransaction(response.data.list); 
        } catch (error) {
          console.error('Error fetching tree view:', error); 
        }
      };
    
      fetchTreeView();
   }, [])
  return (
    <div> 
      <>
        <div className="container">
        <Button
        className="menus-button d-md-none" // Hide on medium and larger screens
        onClick={toggleMenu}
      >
        <FontAwesomeIcon icon={faBars} /> Menu
      </Button>
          <div className="row">
          <div className={`col-md-4 ${menuVisible ? 'd-block' : 'd-none d-md-block'}`}>
          <div className="profile-page container">
          <p>
              <a className="profile-menu" href="/levelplan">
                <FontAwesomeIcon className="me-3" icon={faCalculator} />
                Dashboard
              </a>
            </p>
            <hr className="profile-break" />
            <p>
              <a className="profile-menu" href="/profile">
                <FontAwesomeIcon className="me-3" icon={faUser} />
                Profile
              </a>
            </p>
            <hr className="profile-break" />
            <p>
              <a className="profile-menu" href="/newregister">
                <FontAwesomeIcon className="me-3" icon={faUserPlus} />
                New Registration
              </a>
            </p>
            <hr className="profile-break" />
            <p>
              <a className="profile-menu" href="/treeview">
                <FontAwesomeIcon className="me-3" icon={faUserGroup} />
                Tree View
              </a>
            </p>
            <hr className="profile-break" />
          
            <p>
              <a className="profile-menu" href="/order">
                <FontAwesomeIcon className="me-3" icon={faFile} />
                Order History
              </a>
            </p>
            <hr className="profile-break" />
            <a className="profile-menu" href="/wallet">
              <FontAwesomeIcon className="me-3" icon={faWallet} />
              Wallet
            </a>
            <hr className="profile-break" />
            <p>
              <a className="profile-menu" href="/transaction">
                <FontAwesomeIcon className="me-3" icon={faMoneyCheck} />
                Transaction History
              </a>
            </p>
          </div>
        </div>
            <div className="col-md-8">
              <div className="profile-page container">
              <div className="container mt-2">
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>Sl No</th>
                        <th>Product Name</th>
                        <th>Price</th>
                        <th>Amount</th>
                    </tr>
                </thead>
                <tbody>
                    {transaction.map((transaction) => (
                        <tr key={transaction.id}>
                            <td>{transaction.od_id}</td>
                            <td>{transaction.p_name}</td>
                            <td>{transaction.p_price}</td>
                            <td>{transaction.od_amount}</td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </div>
              </div>
            </div>
          </div>
        </div>
      </>
  
  </div>
  )
}

export default Transaction